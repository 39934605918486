export enum QueriesKeys {
    AUTO_PAYOUT_CONFIG = 'auto-payout-config',
    GET_VERIFICATION_STATE = 'get-verification-state',
    GET_VERIFICATION_TIER_STATUS = 'get-verification-tier-status',
    GET_USER_CONFIG = 'get-user-config',
    CHECK_PAYOUT_STATUS = 'check-payout-status',
    PASSPORT_USER_INFO = 'passport-user-info',
    APPROXIMATE_AMOUNTS = 'approximate-amounts',
    GET_PAYOUT_STATISTICS = 'get-payout-statistics',
    WALLET_BALANCES = 'wallet-balances',
    TIER1_COUNTRIES_LIST = 'tier1-countries-list'
}

export enum MutationKeys {
    DISABLE_AUTO_PAYOUT = 'disable-auto-payouts',
    ENABLE_AUTO_PAYOUT = 'enable-auto-payouts',
    SIGN_DOCUMENTS = 'sign-documents',
    CREATE_TIER_2 = 'create-tier-2',
    CREATE_TIER_3 = 'create-tier-3',
    CREATE_QUESTIONNAIRE = 'create-questionnaire',
    SUBSCRIBE_NETWORK = 'subscribe-network',
    SET_COUNTRY = 'set-country'
}
