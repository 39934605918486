import {atom} from "recoil";
import {TierLevelsSchema} from "@capyfast/contracts";

export const currentVerificationTierAtom = atom<TierLevelsSchema | null>({
    key: 'currentVerificationTierAtom',
    default: null,
})

export const onFinishVerificationAtom = atom<(() => unknown) | null>({
    key: 'onFinishVerificationAtom',
    default: null
})
