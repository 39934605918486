import {useCallback, useEffect} from "react";
import {useRecoilState} from "recoil";

import {useGetVerificationInformationQuery} from "../queries/get-verification-state";
import {Screens} from "../../payout/constants";
import {currentVerificationTierAtom, onFinishVerificationAtom, Tier1StateAtom, Tier1States} from "../../../core/store";
import {useCreateTier3} from "../mutations/create-tier3";
import {PayoutStepAtom} from "../../payout/store/flow";

type useVerificationServiceOptions = {
} | undefined
export const useVerificationService = (props: useVerificationServiceOptions = {}) => {
    const [, setScreen] = useRecoilState(PayoutStepAtom);
    const [currentTier, setCurrentTier] = useRecoilState(currentVerificationTierAtom);
    const [, setTier1State] = useRecoilState(Tier1StateAtom);
    const [onFinish, setOnFinish] = useRecoilState(onFinishVerificationAtom);

    const {
        data,
        refetch,
        isLoading,
        isPending,
        isSuccess,
        isFetching,
        isError
    } = useGetVerificationInformationQuery();

    const isNeedVerification = data?.is_need_verification ?? false;
    const tier = data?.need_verification_level || null;

    const {mutate: createTier3Verification} = useCreateTier3();

    const onStartVerification = useCallback(() => {
        if (isNeedVerification) setCurrentTier(tier);
    }, [isNeedVerification, setCurrentTier, tier])

    const choiceTier = useCallback(() => {
        switch (tier) {
            case '1':
                setTier1State(Tier1States.SDK);
                setScreen(Screens.VERIFICATION_TIER1)
                break;
            case '2':
                setScreen(Screens.VERIFICATION_TIER2);
                break;
            case '3':
                createTier3Verification();
                setScreen(Screens.VERIFICATION_TIER3);
                break;
            default:
                break;
        }
    }, [createTier3Verification, setScreen, setTier1State, tier])

    const onFinishVerification = useCallback(() => {
        setCurrentTier(null);
        refetch().then(() => {
            setTimeout(() => {
                onFinish?.();
                setTier1State(Tier1States.SDK);
            }, 500)
        });
    }, [onFinish, refetch, setCurrentTier, setTier1State]);

    const onCancelVerification = useCallback(() => {
        setCurrentTier(null);
        setTier1State(Tier1States.SDK)
    }, [setCurrentTier, setTier1State])

    useEffect(() => {
        if (currentTier) {
            choiceTier();
        }
    }, [choiceTier, currentTier, onStartVerification]);

    return {
        currentTier,
        onStartVerification,
        onFinishVerification,
        onCancelVerification,
        isNeedVerification,
        setOnFinish: (v: any) => {
            if(typeof v === "function"){
                setOnFinish(() => v)
            }else {
                console.error('onFinish must be a function')
            }
        },
        tier,
        refetch,
        isError: isError,
        isLoading,
        isPending,
        isSuccess,
        setScreen,
        isFetching,
    }
}
