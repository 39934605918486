import {useRecoilState, useRecoilValue} from "recoil";
import {isNeedSignDocsAtom, JWTPayloadSelector} from "core/store";
import {UserLegalDocumentsSchemaType} from "@capyfast/contracts";
import {useEffect} from "react";

import {useUserConfigQuery} from "../queries/user-config";
import {LocalStorageKeys} from "../../../core/constants/local-storage-keys";

export const useUserService = () => {
    const [isNeedSignDocs, setIsNeedSignDocs] = useRecoilState(isNeedSignDocsAtom);
    const userDataPayload = useRecoilValue(JWTPayloadSelector);

    useEffect(() => {
        if (userDataPayload.sso_id) window.localStorage.setItem(LocalStorageKeys.SSO_ID, userDataPayload.sso_id)
    }, [userDataPayload.sso_id]);

    const {
        user,
        isUserLoaded,
        refetch: refetchUserConfig
    } = useUserConfigQuery();

    return {
        payload: userDataPayload,
        isUserLoaded,
        isNeedSignDocs,
        refetchUserConfig,
        setIsNeedSignDocs,
        signDocs: user.onboarding_legal_documents?.filter((item: UserLegalDocumentsSchemaType) => !item.is_signed) ?? [],
        isDisabledManualPayouts: user.is_disabled_manual_payouts
    }
}
