import {memo, ReactNode, useState} from "react";
import {Button, ButtonBorderRadius, ButtonSizes} from "@design-system/ui-kit";
import {formatAmount} from "@capyfast/utils";
import classnames from "classnames";
import {useTranslation} from "react-i18next";
import {sendGaEvent} from "core/utils/sendGaEvent";
import {EGaCategories, EGaEvents, EGaTypes} from "core/typings/enums";
import {useRecoilValue} from "recoil";
import {PlatformsAtom} from "core/store";
import {useParams} from "react-router-dom";
import {Dropdown, DropdownItem, DropdownMenu, DropdownTrigger} from "@nextui-org/dropdown";

import {useGetStatisticsQuery} from "../../../../domain/statistics/queries/get-statistics";
import {useWalletBalances} from "../../../../domain/statistics/queries/wallet-balances";
import {CurrenciesSymbols} from "../../../../core/constants/currencies-symbols";
import {ENV} from "../../../../core/environment";

type BalanceProps = {
    text: string,
    balance: ReactNode,
    className?: string,
    isLoading?: boolean
}

const Balance = memo(({
                          text,
                          balance,
                          className,
                          isLoading
                      }: BalanceProps) => {
    return <div className={className}>
        <div className={'text-body-03 text-label-quaternary'}>{text}</div>
        <div
            className={classnames('font-heading font-extrabold text-header-06 text-label-secondary text-nowrap',
                {'rounded bg-background-fifth text-background-fifth animate-pulse text-nowrap': isLoading})}>{balance}</div>
    </div>
});

export const BalancesInfo = () => {
    const {t, i18n} = useTranslation()
    const {statisticData, isLoading} = useGetStatisticsQuery();
    const params = useParams();
    const platforms = useRecoilValue(PlatformsAtom);
    const activePlatform = platforms.find(item => item.key === params.networkId)
    const {walletsData, isLoading: isLoadingWallets} = useWalletBalances();
    const [isOpen, setIsOpen] = useState(false);

    const currencyCodes = Object
        .keys(walletsData)
        .filter((code) => {
            return !['USD', 'EUR', 'GBP'].includes(code)
        });


    return <div className={'bg-background-tertiary px-size-5 py-10 flex gap-size-3 items-center justify-between'}>
        <div className={'flex grow gap-size-3 justify-between pr-size-3'}>
            <Balance isLoading={isLoading} text={t('Total payouts')}
                     balance={`$ ${formatAmount(statisticData?.total_sum)}`}/>
            <Balance isLoading={isLoading} text={t('Saved time')}
                     balance={t('balancesInfo.savedDays', {count: statisticData?.saved_time ?? 0})}/>
            <Balance text={t('balancesInfo.USDWallet')}
                     balance={`${CurrenciesSymbols['USD']} ${formatAmount(walletsData.USD)}`}
                     isLoading={isLoadingWallets}
                     className={'border-l-s2 border-interface-sixth pl-size-3'}/>
            <Balance text={t('EUR wallet')}
                     balance={`${CurrenciesSymbols['EUR']} ${formatAmount(walletsData.EUR)}`}
                     isLoading={isLoadingWallets}/>
            <Balance text={t('GBP wallet')}
                     balance={`${CurrenciesSymbols['GBP']} ${formatAmount(walletsData.GBP)}`}
                     isLoading={isLoadingWallets}/>
            {!!currencyCodes.length && <Dropdown className={'bg-background-tertiary'}
                                               onOpenChange={(v) => setIsOpen(v)}
                                               classNames={{
                                                   content: 'p-0 bg-background-tertiary rounded',
                                               }}>
                <DropdownTrigger>
                    <div className={'cursor-pointer'}>
                        <Balance text={t('currenciesList.Other')}
                                 balance={<span className={'text-brand-primary flex gap-2 items-center'}>
                                     {t('currenciesList.More', {count: currencyCodes.length})}
                                     <svg width="21"
                                          height="20"
                                          className={isOpen ? '' : 'rotate-180'}
                                          viewBox="0 0 21 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.91699 11.6667L10.2503 8.33341L13.5837 11.6667"
                                              stroke="currentColor"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"/>
                                      </svg>
                        </span>}
                                 isLoading={isLoadingWallets}/>
                    </div>
                </DropdownTrigger>
                <DropdownMenu color={'secondary'} classNames={{
                    base: 'p-0',
                    list: 'p-0 gap-0',
                }}
                              itemClasses={{
                                  base: 'border-b last:border-b-0 rounded-none py-size-1 px-size-1 data-[hover=true]:bg-label-sixth'
                              }}>
                    {currencyCodes.map(code => {
                        return <DropdownItem isReadOnly key={'usd'}>
                            <div className={'flex justify-between items-center gap-8'}>
                                <div className={'flex gap-2 items-center'}>
                                    <span className={'text-label-tertiary text-body-03 font-medium'}>{code}</span>
                                    <span
                                        className={'text-body-04 text-label-fifth'}>{t(`currenciesList.currenciesName.${code}`, {defaultValue: ''})}</span>
                                </div>
                                <div className={'text-body-02 font-medium'}>
                                    ${formatAmount(walletsData[code as 'EUR'])}
                                </div>
                            </div>
                        </DropdownItem>
                    })}
                </DropdownMenu>
            </Dropdown>}
        </div>
        <div>
            <Button onClick={() => {
                sendGaEvent(EGaEvents.CLICK_PLATFORM_PROFILE, {
                    event_option1: EGaCategories.HOME_PAGE,
                    event_option2: EGaTypes.COMING_SOON,
                    event_option4: activePlatform?.key
                });
            }} target={'_blank'} href={ENV.WALLET_LINK} size={ButtonSizes.xl}
                    borderRadius={ButtonBorderRadius.l}>{t('Open Wallet')} →</Button>
        </div>
    </div>
}
