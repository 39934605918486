import { GA_IDS } from 'core/constants/trackerIds';
import { ENV } from 'core/environment';
import { getClientId } from 'core/utils/ga';
import { sendGaEvent } from 'core/utils/sendGaEvent';
import { useCallback, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';


const debugMode = ENV.IS_DEV === 'true';

export const useGAInit = () => {
  const location = useLocation();
  const initGtag = useCallback((ids: string[]) => {
    if (!ids.length) {
      return;
    }
    const initArray = ids.map((id) => ({
      trackingId: id,
      testMode: debugMode,
      gtagOptions: { send_page_view: false },
    }));
    ReactGA.initialize(initArray);
    ids.forEach((id) => {
      ReactGA.event('initialize_cookie', {
        send_to: id,
        non_interaction: true,
      });
    });
  }, []);

  useEffect(() => {
    initGtag((GA_IDS as string[])?.filter((id) => !!id));
  }, [initGtag]);

  const pageViewEvent = useCallback(() => {
    const clientId = getClientId();
    if (typeof clientId === 'undefined' || !clientId) {
      setTimeout(function () {
        pageViewEvent();
      }, 500);
    } else {
      sendGaEvent('page_view');
    }
  }, []);

  useEffect(() => {
    pageViewEvent();
  }, [location]); // eslint-disable-line
};
