export const getSelectItemByValue = (
    options: any[],
    selectedValue: any | any[] | null,
): any[] => {
    let selectedOptions: Omit<any, 'selected'>[];
    if(!selectedValue) return [];
    if (Array.isArray(selectedValue)) {
        selectedOptions = options.filter((option) => selectedValue.includes(option?.value));
    } else {
        const selected = options.find(({value}) => value === selectedValue);
        selectedOptions = selected ? [selected] : [];
    }

    return selectedOptions.map((option) => ({...option, selected: true}));
};


export const getSingleValueFromSelectItem = (value: any[]): any => {
    return value?.[0]?.value;
};
