import {useState} from "react";
import {useTranslation} from "react-i18next";

import {PlatformSchema} from "../../../../core/store";
import {useUser} from "../../../../domain/user/providers/user";
import {PlatformStatistics} from "../platform-statistics";
import { sendGaEvent } from "core/utils/sendGaEvent";
import { EGaCategories, EGaEvents, EGaTypes } from "core/typings/enums";

function getDomain(url: string) {
    try {
        return new URL(url).hostname.replace('www.', '');
    } catch (e) {
        console.error(e);
        return null;
    }
}

type PlatformInfoProps = {
    platform: PlatformSchema,
}

function getTextFromFirstTag(htmlString: string, defaultText: string | null = null): {
    tagCount: number
    text: string | null
} {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const firstTag = doc.body.firstChild;
    const tagCount = doc.body.childElementCount;
    return {
        tagCount: tagCount,
        text: firstTag ? firstTag.textContent : defaultText
    };
}

export const PlatformInfo = ({platform}: PlatformInfoProps) => {
    const {t} = useTranslation()

    const [isCollapse, setIsCollapse] = useState<boolean>(true)
    const {payload}= useUser();

    const descriptionData = getTextFromFirstTag(t(platform.description), t(platform.short_description))

    return <div>
        <div className={'space-y-size-3'}>
            <div className={'flex gap-size-0.5'}>
                <div className={'grow space-y-size-0.5'}>
                    <div
                        className={'text-header-04 text-label-primary font-extrabold font-heading'}>{t(platform.title)}</div>
                    <a href={platform.url} target={'_blank'} className={'text-label-primary hover:text-label-success'} rel="noreferrer">{getDomain(platform.url)}</a>
                </div>
                <div>
                    {platform.logo_url && <img className={'h-[2.75rem]'} src={platform.logo_url} alt={platform.title}/>}
                </div>
            </div>
            <div className={'mt-6'}>
                <div className={'prose max-w-full leading-6'} dangerouslySetInnerHTML={{__html: isCollapse && descriptionData.text ? descriptionData.text : t(platform.description)}}/>
                {descriptionData.tagCount > 1 && <div className={'text-brand-primary mt-size-0.5 cursor-pointer'}
                     onClick={() => {
                        setIsCollapse((prev) => !prev);
                        isCollapse && sendGaEvent(EGaEvents.CLICK_READ_MORE, {
                            event_option1: EGaCategories.PLATFORM_PROFILE,
                            event_option2: EGaTypes.ABOUT_ACCOUNT,
                            event_option4: platform.title,
                            });
                     }}>
                        {isCollapse ? t('Read more') : t('Hide')}
                </div>}
            </div>

            {platform.active && <>
                <PlatformStatistics/>

                <div>
                    <div className={'flex justify-between items-center'}>
                        <div className={'text-header-07 font-extrabold font-heading'}>{t('Connected accounts')}</div>
                    </div>
                </div>

                <div className={'rounded-xl border border-label-seventh'}>
                    <div className={'bg-label-seventh px-4 py-4 font-medium'}>
                        {t('Account')}
                    </div>
                    <div className={' text-label-secondary p-4'}>{payload.email}</div>
                </div>
            </>}


        </div>
    </div>
}
