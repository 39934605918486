import {ReactComponent as SuccessIcon} from "assets/svg/interface-icons/success.svg";
import {Button, ButtonSizes, ButtonThemes} from "@design-system/ui-kit";
import {Link} from "react-router-dom";
import { sendGaEvent } from "core/utils/sendGaEvent";
import { EGaCategories, EGaEvents, EGaTypes } from "core/typings/enums";
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

import {Paths} from "../../../../core/router";
import {ENV} from "../../../../core/environment";

type SuccessEnableAutoPayoutProps = {
    onClose: () => unknown
}

export const SuccessEnableAutoPayout = ({onClose}: SuccessEnableAutoPayoutProps) => {
    const {t} = useTranslation()

    useEffect(() => {
        sendGaEvent(EGaEvents.SHOW_PAYOUT_ACTIVATING, {
            event_option1: EGaCategories.REQUEST_PAYOUT,
            event_option2: EGaTypes.ACTIVATING_PAYOUT,
            // event_option4: TODO: какая платформа ?
        });
    }, [])

    return <div className={'flex flex-col gap-5 justify-center items-center h-full'}>
        <div className={'w-20 aspect-square text-brand-primary'}><SuccessIcon/></div>
        <div className={'text-center font-heading text-header-03 font-extrabold max-w-[22.5rem]'}>Auto payout activated </div>
        <div className={'text-center text-body-01 text-label-tertiary max-w-[20.5rem]'}>
            <Trans i18nKey="nextPaymentsMessage" components={{ link: <Link onClick={() => {
                    sendGaEvent(EGaEvents.CLICK_HISTORY, {
                        event_option1: EGaCategories.REQUEST_PAYOUT,
                        event_option2: EGaTypes.PAYOUT_RECEIVED,
                        // event_option4: TODO: какая платформа ?
                    });
                }} to={Paths.HISTORY} className={'underline'}>history</Link> }}>
                You will be able to see the next payments in the <link>history</link>.
            </Trans>
        </div>
        <div className={'flex gap-1 justify-center'}>
            <Button onClick={() => {
                sendGaEvent(EGaEvents.CLICK_OPEN_WALLET, {
                    event_option1: EGaCategories.REQUEST_PAYOUT,
                    event_option2: EGaTypes.PAYOUT_RECEIVED,
                    // event_option4: TODO: какая платформа ?
                    });
            }}
                    size={ButtonSizes.xl}
                    theme={ButtonThemes.ghostFill}
                    href={ENV.WALLET_LINK}
                    target={'_blank'}
            >{t('Open Wallet')}</Button>
            <Button size={ButtonSizes.xl} onClick={() => {
                sendGaEvent(EGaEvents.CLICK_CLOSE, {
                    event_option1: EGaCategories.REQUEST_PAYOUT,
                    event_option2: EGaTypes.PAYOUT_RECEIVED,
                    // event_option4: TODO: какая платформа ?
                    });
                onClose()
            } }>{t('Close')}</Button>
        </div>
    </div>
}
