export enum ELanguages {
    EN = 'en',
    RU = 'ru',
}

export enum EGaRoles {
    PUBLISHER = 'publisher',
    ADVERTISER = 'advertiser'
}

export enum EGaConditions {
    USE_NEW_CONDITIONS = 'use new conditions',
    SAVE_YOUR_INSTANT_PAYOUT_SETTINGS = 'save your instant payout settings',
    CAPYFAST_TERMS_AND_CONDITIONS = 'capyfast terms and conditions',
    CAPYFAST_PRIVACY_POLICY = 'capyfast privacy policy',
}

export enum EGaLayoutBlocks {
    MENU = 'menu',
}

export enum EGaMenu {
    HOME = 'home',
    HISTORY = 'history',
    WALLET = 'wallet',
    QUESTION = 'question',
}

export enum EGaEvents {
    SEND_REGISTRATION_SUCCESS = 'send_registration_success',
    SHOW_POPUP_IP_TO_CAPYFAST = 'show_popup_ip_to_capyfast',
    CLICK_SELECT_OPTION = 'click_select_option',
    CHECKBOX_PRIVACY = 'checkbox_privacy',
    CLICK_JOIN_CAPYSAST = 'click_join_capyfast',
    CLICK_HOME = 'click_home',
    CLICK_WALLET = 'click_wallet',
    CLICK_QUESTION = 'click_question',
    CLICK_HISTORY = 'click_history',
    SHOW_WELCOME_PAGE = 'show_welcome_page',
    CLICK_START_ONBOARDING = 'click_start_onboarding',
    CLICK_OKAY_GOT_IT = 'click_okay_got_it',
    CLICK_READ = 'click_read',
    CHECKBOX_DOCUMENTS = 'checkbox_documents',
    CLICK_BACK = 'click_back',
    CLICK_NEXT = 'click_next',
    CLICK_GO_TO_DASHBOARD = 'click_go_to_dashboard',
    SHOW_HELP_POPUP = 'show_help_popup',
    CLICK_COPY_EMAIL = 'click_copy_email',
    CLICK_GET_AUTO_PAYOUT = 'click_get_auto_payout',
    CLICK_ADD_ACCOUNT = 'click_add_account',
    CLICK_PLATFORM_PROFILE = 'click_platform_profile',
    CLICK_OPEN_WALLET = 'click_open_wallet',
    CLICK_MENU = 'click_menu',
    CLICK_COPY_SUPPORT_EMAIL = 'click_copy_support_email',
    CLICK_INSTRUCTIONS = 'click_instructions',
    CLICK_FAQ = 'click_faq',
    CLICK_FOOTER = 'click_footer',
    CLICK_LANGUAGE = 'click_language',
    CLICK_SUBSCRIBE_FOR_UPDATE = 'click_subscribe_for_update',
    SHOW_SUBSCRIBE_FORM_SEND_SUCCESS = 'show_subscribe_form_send_success',
    SEND_ACCOUNT_ADDED_SUCCESS = 'send_account_added_success',
    CLICK_READ_MORE = 'click_read_more',
    CLICK_DOWNLOAD_DETALISATION = 'click_download_detalisation',
    CLICK_GET_ADVANCE_PAYOUT = 'click_get_advance_payout',
    CHECKBOX_ACCEPT_TERMS = 'checkbox_accept_terms',
    CLICK_I_WANT_ONE_TIME = 'click_i_want_one_time',
    CLICK_ACTIVATE_AUTO_PAYOUT = 'click_activate_auto_payout',
    SHOW_ADD_ACCOUNT_BLOCKED = 'show_add_account_blocked',
    CLICK_INFORMATION_HERE = 'click_information_here',
    SHOW_PAYOUT_ACTIVATING = 'show_payout_activating',
    SHOW_PAYOUT_ACTIVATED_SUCCESS = 'show_payout_activated_success',
    CLICK_CLOSE = 'click_close',
    CLICK_DISABLE_AUTO_ADVANCE_PAYOUT = 'click_disable_auto_advance_payout',
    SHOW_DISABLE_POPUP = 'show_disable_popup',
    CLICK_DISABLE = 'click_disable',
    CLICK_STAY_WITH_AUTO = 'click_stay_with_auto',
    CLICK_DISABLE_ANYWAY = 'click_disable_anyway',
    CLICK_REACTIVE_AUTO = 'click_reactive_auto',
    SHOW_REACTIVE_AUTO_POPUP = 'show_reactive_auto_popup',
    CHECKBOX_TERMS = 'checkbox_terms',
    SHOW_PAYOUT_MONEY_TRANSFERRED_SUCCES = 'show_payout_money_transferred_success',
    CLICK_TRANSACTION_MORE_DETAILS = 'click_transaction_more_details',
    CLICK_CONTINUE = 'click_continue',
    CLICK_CONTINUE_ON_PHONE = 'click_continue_on_phone',
    CLICK_VIEW_OUR_GUIDE = 'click_view_our_guide',
    SEND_VERIFICATION_SUCCESS = 'send_verification_success',
    SHOW_VERIFICATION_BLOCKED = 'show_verification_blocked',
    SHOW_SOMETHING_WENT_WRONG = 'show_something_went_wrong',
    CLICK_RESTART_THE_PROCESS = 'click_restart_the_process',
    CLICK_TYPE_OF_TRAFFIC = 'click_type_of_traffic',
    CLICK_NETWORK = 'click_network',
    ENTER_PERCENTAGE_NETWORK = 'enter_percentage_network',
    SHOW_VERIFICATION_REQUIRED = 'show_verification_required',
    CLICK_GO_TO_PAYMENT = 'click_go_to_payment',
    CLICK_GO_TO_CATALOG = 'click_go_to_catalog',
    CLICK_GO_VERIFICATION = 'click_go_verification',
}

export enum EGaCategories {
    REGISTRATION = 'registration',
    WELCOME_PAGE = 'account;welcome page',
    HOME_PAGE = 'account;home page',
    MENU = 'account;menu',
    HELP_AND_SUPPORT = 'account;help and support',
    FOOTER = 'account;footer',
    ADD_ACCOUNT = 'account;add account',
    PLATFORM_PROFILE = 'account;platform profile',
    REQUEST_PAYOUT = 'account;request payout',
    AUTO_ADVANCE_PAYOUT_ENABLED = 'account;auto advance payout enabled',
    HISTORY = 'account;history',
    VERIFICATION = 'account;verification',
}

export enum EGaTypes {
    FORM_SEND = 'form send',
    POPUP_INSTANTPAYOUT_TO_CAPYFAST_USERS_WITHOUT_IP_AUTO = 'popup instantpayout to capyfast users without ip auto',
    POPUP_INSTANTPAYOUT_TO_CAPYFAST_USERS_WITH_IP_AUTO = 'popup instantpayout to capyfast users with ip auto',
    STEP_ONE_NETWORKS = 'step1 networks',
    STEP_TWO_DOCUMENTS = 'step2 documents',
    STEP_THREE_DOCUMENTS = 'step3 documents',
    HELP_POPUP = 'step3 documents;help popup',
    AVAILABLE_PLATFORMS = 'platforms;available platforms',
    COMING_SOON_PLATFORM = 'platforms;coming soon',
    HEADER = 'header',
    WELCOME = 'welcome',
    MENU = 'menu',
    HELP_AND_SUPPORT = 'help and support',
    FOOTER = 'footer',
    COMING_SOON = 'coming soon',
    ABOUT_ACCOUNT = 'about account',
    CONNECTED_ACCOUNTS = 'about account;connected accounts',
    REQUEST_FOR_AUTO_ADVANCE_PAYOUT = 'request for auto advance payout',
    NO_NEW_PAYOUTS = 'request for auto advance payout;no new payouts',
    NO_NEW_PAYOUTS_POPUP = 'request for auto advance payout;no new payouts;popup',
    ACTIVATING_PAYOUT = 'activating payout',
    PAYOUT_RECEIVED = 'request for auto advance payout;payout received',
    SERVICE_NOT_AVAILABLE_TO_YOU = 'service not available to you',
    HISTORY_OF_RECENT_PAYMENTS = 'history of recent payments',
    DISABLE_BUTTON_INACTIVE = 'popup disable auto advance payout;disable button inactive',
    DISABLE_BUTTON_ACTIVE = 'popup disable auto advance payout;disable button active',
    AUTO_ADVANCE_PAYOUT_ENABLED_FOR_MONTH_MORE = 'auto advance payout enabled for 30 days more',
    POPUP_REACTIVE_AUTO_ADVANCE_PAYOUT = 'popup reactive auto advance payout',
    REQUEST_FOR_ONE_TIME_ADVANOUT = 'request for one time advance payout',
    MAKING_PAYOUT = 'request for one time advance payout;making payout',
    PAYOUT_RECEIVEDS = 'request for one time advance payout;payout receiveds',
    DETAILS_OF_TRANSACTION = 'details of transaction',
    POPUP_DETAILS_OF_TRANS = 'popup details of transaction',
    FIRST_PAYMENT_STEP_ONE = 'first payment;step 1',
    FIRST_PAYMENT_STEP_TWO = 'first payment;step 2',
    STEP_TWO_SERVICE_NOT_AVAILABLE_TO_YOU = 'first payment;step 2;service not available to you',
    SOMETHING_WENT_WRONG = 'first payment;step 2;something went wrong',
    GOES_TO_PAYMENT_STEP_ONE = '5k loan;goes to payment;step 1',
    DIRECT_TRAFFIC = 'direct traffic',
    GOES_TO_PAYMENT_STEP_TWO = '5k loan;goes to payment;step 2',
    GOES_TO_PAYMENT_STEP_THREE = '5k loan;goes to payment;step 3',
    GOES_TO_PAYMENT = '5k loan;goes to payment',
    FIVE_K_LOAN = '5k loan',
    BANNER_PAYOUT_RECEIVED = '5k loan;after payment;banner payout received',
    BANNER_AUTO_ADVANCE_PAYOUT_ENABLE = '5k loan;after payment;banner auto advance payout enable',
    TEN_K_LOAN = '10k loan'
}
