import {Loader, LoaderSizes} from "@design-system/ui-kit";
import { PlatformSchema } from "core/store";
import { EGaCategories, EGaEvents, EGaTypes } from "core/typings/enums";
import { sendGaEvent } from "core/utils/sendGaEvent";
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

export const PendingPayout = ({isActivateAuto, activePlatform}: {isActivateAuto: boolean, activePlatform?: PlatformSchema}) => {
    const {t} = useTranslation()

    useEffect(() => {
        if (isActivateAuto) {
            sendGaEvent(EGaEvents.SHOW_PAYOUT_ACTIVATING, {
                event_option1: EGaCategories.REQUEST_PAYOUT,
                event_option2: EGaTypes.ACTIVATING_PAYOUT,
                event_option4: activePlatform?.title
              });
        } else {
            sendGaEvent(EGaEvents.SHOW_PAYOUT_ACTIVATING, {
                event_option1: EGaCategories.REQUEST_PAYOUT,
                event_option2: EGaTypes.MAKING_PAYOUT,
                event_option4: activePlatform?.title
              });
        }
    }, [isActivateAuto, activePlatform])

    if (isActivateAuto)
        return <div className={'flex flex-col gap-5 justify-center items-center h-full'}>
            <div className={'w-20 aspect-square text-brand-primary'}>
                <Loader size={LoaderSizes.l}/>
            </div>
            <div className={'text-center text-header-03 font-heading font-extrabold max-w-[22.5rem]'}>
                {t('Activating Auto Instant Payouts')}
            </div>
            <div className="text-center text-body-01 text-label-tertiary max-w-[20.5rem]">
            <Trans i18nKey="payoutProcessInstructions">
                Please wait, the payout process usually happens within 2 min, but sometimes it may take up to 60 min.
                <br />
                <br />
                You will find the final amount in the Wallet after we process the payout.
            </Trans>
            </div>
        </div>

    return <div className={'flex flex-col gap-5 justify-center items-center h-full'}>
        <div className={'w-20 aspect-square text-brand-primary'}>
            <Loader size={LoaderSizes.l}/>
        </div>
        <div className={'text-center text-header-03 font-heading font-extrabold max-w-[22.5rem]'}>
            {t('Making Instant Payout')}
        </div>
        <div className="text-center text-body-01 text-label-tertiary max-w-[20.5rem]">
        <Trans i18nKey="payoutProcessInstructions">
            Please wait, the payout process usually happens within 2 min, but sometimes it may take up to 60 min.<br />
            <br />
            You will find the final amount in the Wallet after we process the payout.
        </Trans>
        </div>
    </div>
}
