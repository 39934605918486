import {Checkbox, Input} from "@design-system/ui-kit";
import { sendGaEvent } from "core/utils/sendGaEvent";
import { EGaCategories, EGaEvents, EGaTypes } from "core/typings/enums";
import {useTranslation} from "react-i18next";

import {useTier2} from "../../../../../domain/verification/providers";
import {VerificationNavigation} from "../../navigation/navigation";

import {QuestionnaireHeader} from "./header";

export const VerificationTier2Q2 = () => {
    const {t} = useTranslation()

    const {
        networksVariants,
        q2Values,
        setQ2Values,
        q2OtherValue,
        setQ2OtherValue,
        onNext,
        onBack,
        isQ2Verified
    } = useTier2();

    const handleBlur = () => {
        sendGaEvent(EGaEvents.CLICK_NETWORK, {
          event_option1: EGaCategories.VERIFICATION,
          event_option2: EGaTypes.GOES_TO_PAYMENT_STEP_TWO,
          event_option4: q2OtherValue
        });
      };

    return <>
        <div>
            <QuestionnaireHeader/>
        </div>

        <div>
            <h2 className={'text-header-06 font-heading font-bold text-label-secondary mb-6'}>
                {t('2. What other networks do you work with?')}
            </h2>
            <div className={'grid grid-cols-2 gap-size-0.5'}>
                {Object.keys(networksVariants).map((key) => {
                    return <div
                        className={`rounded border border-interface-sixth p-size-0.5 cursor-pointer ${q2Values?.[key] ? 'bg-background-tertiary' : ''}`}
                        key={key}
                        onClick={() => {
                            setQ2Values((prev) => {
                                return {...prev, [key]: prev?.[key] ? !prev?.[key] : true}
                            })
                        }}>
                        <Checkbox onChange={(newValue) => {
                            sendGaEvent(EGaEvents.CLICK_NETWORK, {
                                event_option1: EGaCategories.VERIFICATION,
                                event_option2: EGaTypes.GOES_TO_PAYMENT_STEP_TWO,
                                event_option4: newValue ? `${key};enable` : `${key};disable`
                                });
                        }}
                                  checked={q2Values?.[key]}
                                  className={'pointer-events-none'}
                                  label={<span className={'text-body-02 text-label-secondary'}>
                                      {/* t('verification.tier2.answersStep2Variants.other') */}
                            {t('verification.tier2.answersStep2Variants.' + key, {defaultValue: networksVariants[key]})}
                        </span>}/>
                    </div>
                })}
            </div>
            <div className={'mt-size-0.5'}>
                <Input value={q2OtherValue}
                       onBlur={handleBlur}
                       nativePlaceholder
                       onChange={(v) => setQ2OtherValue(v)}
                       disabled={!q2Values?.other}
                       placeholder={t('verification.tier2.YourVariant')}/>
            </div>
        </div>
        <VerificationNavigation onNext={onNext}
                                onBack={onBack}
                                isDisabledNext={!isQ2Verified}/>
    </>
}
