import {useRecoilValue} from "recoil";
import {
    helpNavigationItemsAtom,
    mainNavigationItemsAtom,
    secondaryNavigationItemsAtom
} from "core/store/navigation.store";
import {ReactComponent as LogoutIcon} from "assets/svg/navigation/logout.svg";
import {useCallback} from "react";

import {useAppAuth} from "../../../core/app/app-auth";
import {sendGaEvent} from "../../../core/utils/sendGaEvent";
import {EGaCategories, EGaEvents, EGaLayoutBlocks, EGaMenu} from "../../../core/typings/enums";

import {AsideItem} from "./item";
import {AsideItemsType, AsideItemType} from './aside.types'

import Style from './aside.module.scss'

export const Aside = () => {
    const mainItems = useRecoilValue(mainNavigationItemsAtom);
    const secondaryItems = useRecoilValue(secondaryNavigationItemsAtom);
    const helpItems = useRecoilValue(helpNavigationItemsAtom);

    const {logOut} = useAppAuth();
    const logoutItem: AsideItemType = {
        key: 'logout',
        icon: <LogoutIcon/>,
        title: '',
        isActive: false,
    };

    const onPressItem = useCallback((item: AsideItemsType[0]) => {
        switch (item.key){
            case 'home':
                sendGaEvent(EGaEvents.CLICK_HOME, {
                    event_option1: EGaCategories.MENU,
                    event_option2: EGaLayoutBlocks.MENU,
                    event_option4: EGaMenu.HOME
                });
                break;
            case 'history':
                sendGaEvent(EGaEvents.CLICK_HISTORY, {
                    event_option1: EGaCategories.MENU,
                    event_option2: EGaLayoutBlocks.MENU,
                    event_option4: EGaMenu.HISTORY
                });
                break;
            case 'wallet':
                sendGaEvent(EGaEvents.CLICK_WALLET, {
                    event_option1: EGaCategories.MENU,
                    event_option2: EGaLayoutBlocks.MENU,
                    event_option4: EGaMenu.WALLET
                });
                break;
            case 'help':
                sendGaEvent(EGaEvents.CLICK_QUESTION, {
                    event_option1: EGaCategories.MENU,
                    event_option2: EGaLayoutBlocks.MENU,
                    event_option4: EGaMenu.QUESTION
                });
                break;
        }
    }, [])

    return <aside className={`${Style.aside} bg-white rounded-2xl shadow-2xl`}>
        <nav className={Style.navigation}>
            {mainItems.map((item) => (
                <AsideItem key={item.key}
                           onPress={onPressItem}
                           item={item}/>
            ))}
            <div className={Style.divider}></div>
            {secondaryItems.map((item) => (
                <AsideItem key={item.key}
                           onPress={onPressItem}
                           item={item}/>
            ))}
        </nav>
        <nav className={`${Style.footer} mt-8`}>
            {helpItems.map((item) => (
                <AsideItem key={item.key}
                           onPress={onPressItem}
                           item={item}/>
            ))}
            <AsideItem className={'!text-label-danger'} item={logoutItem} onPress={logOut}/>
        </nav>
    </aside>
}
