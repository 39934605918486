
export const environment: {[key: string]: string} = {
  "MY_ACCOUNT_LINK": "https://app.capyfast.com/auth/realms/admitad/account",
  "GA4_GLOBAL_ID": "G-32HHLCD33P",
  "MODE": "production",
  "WALLET_LINK": "https://wallet.capyfast.com/",
  "SSO_URL": "https://login.mitgo.com",
  "TOTP_URL": "https://app.capyfast.com/auth/realms/admitad/account/totp",
  "CLIENT_URL": "https://app.capyfast.com/",
  "SSO_REALM": "users",
  "BACKEND_URL": "https://app.capyfast.com/",
  "GA4_BUSINESS_ID": "G-1M48DCR6G3",
  "SSO_CLIENT_ID": "capyfast_front"
};
