import {environment as DefaultEnv} from './default-environment'

declare global {
    interface Window {
        ENV: {
            MODE?: string,
            SSO_URL?: string,
            SSO_REALM?: string,
            SSO_CLIENT_ID?: string,
            CLIENT_URL?: string,
            BACKEND_URL?: string,
            TOTP_URL?: string,
            MY_ACCOUNT_LINK?: string,
            IS_DEV?: string | null,
            CLARITY?: string
            GA4_BUSINESS_ID?: string,
            GA4_GLOBAL_ID?: string,
            ANALYTICS_DEBUG_MODE?: string
            WALLET_LINK?: string
        }
    }
}

window.ENV = {...DefaultEnv}

if (!window?.ENV?.IS_DEV) window.ENV.IS_DEV = window.localStorage.getItem('dev')

const ENV = window.ENV;

export {ENV}
export default ENV
