import {BrowserRouter, Route, Routes, useLocation} from "react-router-dom";
import React, {ReactNode, useEffect} from "react";
import {AnimatePresence, motion} from "framer-motion";
import { EGaCategories, EGaEvents, EGaTypes } from "core/typings/enums";
import { sendGaEvent } from "core/utils/sendGaEvent";
import { useGAInit } from "core/hooks/useGaInit/useGaInit";

import {NotFoundPage} from "components/@pages/not-found";

import {Layout} from "../../components/molecules/layout";
import {HomePage} from "../../components/@pages/home";
import {History} from "../../components/@pages/history/history";
import {PlatformsPage} from "../../components/@pages/platforms";
import {OnboardingRouter} from "../../domain/user/routers/onboarding-router";
import {Support} from "../../components/@pages/support";
import {I18NProvider} from "../../domain/i18n/providers/i18n";

import {Paths} from "./paths";
import {PrivateRouter} from "./private-router";

export const AppRoutes = () => {
    return (
        <BrowserRouter>
            <RoutesWithAnimation/>
        </BrowserRouter>
    );
}

const RouteAnimate = ({children}: { children: ReactNode }) => {
    return <motion.div transition={{
        ease: "linear",
        duration: 0.1,
    }}
                       className={'min-h-full grow flex flex-col'}
                       initial={{opacity: 0.5, scale: 0.99}}
                       animate={{opacity: 1, scale: 1}}
                       exit={{opacity: 0, scale: 0.99}}>{children}</motion.div>
}

const AnalyticsRoute = ({ path, element }: { path: string, element: ReactNode }) => {
    useEffect(() => {
        sendGaEvent(EGaEvents.CLICK_MENU, {
            event_option1: EGaCategories.MENU,
            event_option2: EGaTypes.MENU,
            event_option4: path === Paths.HOME ? '/home/' : path,
            });
    }, [path]);

    return <RouteAnimate>{element}</RouteAnimate>;
};

const RoutesWithAnimation = () => {
    const location = useLocation();
    useGAInit();
    
    return (
        <AnimatePresence mode={'wait'}>
            <Routes key={location.key}>
                <Route element={<I18NProvider><Layout/></I18NProvider>}>
                    <Route element={<PrivateRouter/>}>
                        <Route element={<OnboardingRouter/>}>
                            <Route path={Paths.HOME}
                                   element={<AnalyticsRoute path={Paths.HOME} element={<HomePage/>} />}>
                            </Route>
                            <Route path={`${Paths.PLATFORMS}:networkId`}
                                   element={<AnalyticsRoute path={Paths.PLATFORMS} element={<PlatformsPage/>} />}>
                            </Route>
                            <Route path={Paths.HISTORY}
                                   element={<AnalyticsRoute path={Paths.HISTORY} element={<History/>} />}>
                            </Route>
                        </Route>
                        <Route path={Paths.SUPPORT} element={<AnalyticsRoute path={'question'} element={<Support/>} />}></Route>
                    </Route>
                    <Route path={'*'} element={<NotFoundPage/>}/>
                </Route>
            </Routes>
        </AnimatePresence>
    );
}
