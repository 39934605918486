import {atom} from "recoil";

export enum Tier1States {
    SDK,
    SUCCESS,
    FAILED,
    NEED_ACTION
}

export const Tier1StateAtom = atom<Tier1States>({
    key: 'Tier1StateAtom',
    default: Tier1States.SDK
})
