import {useQuery} from "@tanstack/react-query";
import {getVerificationTier1Countries} from "@capyfast/api";
import {CountriesSchema} from "@capyfast/contracts";
import {useRecoilValue} from "recoil";

import {QueriesKeys} from "../../../core/constants/queries-keys";
import {isApiReadySelector} from "../../../core/store";

export const useCountriesList = () => {
    const isReady = useRecoilValue(isApiReadySelector);
    const {
        data,
        isError,
        isLoading,
        isSuccess,
        isPending,
        refetch
    } = useQuery({
        queryKey: [QueriesKeys.TIER1_COUNTRIES_LIST],
        enabled: isReady,
        queryFn: () => getVerificationTier1Countries().then(response => {
            return CountriesSchema.parse(response.data);
        })
    })

    return {
        data,
        isError,
        isLoading,
        isSuccess,
        isPending,
        refetch
    }
}
