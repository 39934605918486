import {Button, ButtonSizes, Input} from "@design-system/ui-kit";
import {useCallback, useState} from "react";
import {SubscribeParamsSchema} from "@capyfast/contracts";
import {ReactComponent as SuccessIcon} from "assets/svg/platforms/subscribe-success.svg";
import {useTranslation} from "react-i18next";
import {EGaCategories, EGaEvents, EGaTypes} from "core/typings/enums";
import {sendGaEvent} from "core/utils/sendGaEvent";

import {useSubscribeNetwork} from "../../../../domain/subscribe/mutations/subscribe-network";
import {useUser} from "../../../../domain/user/providers/user";

type SubscribeProps = {
    network?: string
}
export const Subscribe = ({network}: SubscribeProps) => {
    const {t} = useTranslation()

    const {payload} = useUser();
    const [isInvalid, setIsInvalid] = useState<boolean>(false);
    const {
        subscribe,
        isPending,
        isError,
        isSuccess,
        reset,
    } = useSubscribeNetwork();
    const [email, setEmail] = useState<string>(payload.email)

    const onSubmit = useCallback(() => {
        try {
            SubscribeParamsSchema.parse({email, network_name: network});
            subscribe(SubscribeParamsSchema.parse({email, network_name: network}));
        } catch (e) {
            setIsInvalid(true);
        }


        sendGaEvent(EGaEvents.CLICK_SUBSCRIBE_FOR_UPDATE, {
            event_option1: EGaCategories.ADD_ACCOUNT,
            event_option2: EGaTypes.COMING_SOON,
            event_option4: '', //@todo Ключ платформы
        });

    }, [email, network, subscribe]);

    return <div
        className={'flex flex-col justify-center items-center bg-background-tertiary rounded-xl p-size-3 min-h-[80dvh]'}>
        {isSuccess
            ? <>
                <SuccessIcon className={'mb-4'}/>
                <div
                    className={'text-header-03 font-heading text-label-primary mb-6 text-center font-extrabold max-w-[25rem]'}>
                    {t('Thank you for subscribing for our updates!')}
                </div>
                <div className={'text-body-02 text-label-quaternary text-center mb-6'}>
                    {t("We'll notify you when the network is available")}
                </div>
            </>
            : <>
                <div className={'text-header-03 font-heading text-label-primary mb-6 font-extrabold'}>
                    {t('Coming soon')}
                </div>
                <div className={'text-body-02 text-label-quaternary mb-6'}>
                    {t('This network is not yet available, but will be soon')}
                </div>
                <div className={'flex flex-col justify-center items-center gap-4'}>
                    <div className={'max-w-full w-[20rem]'}>
                        <Input placeholder={'Email'} value={email} error={isInvalid} onChange={(v) => {
                            setIsInvalid(false);
                            setEmail(v);
                        }}/>
                    </div>
                    <Button fluid size={ButtonSizes.xl} onClick={onSubmit} loading={isPending}>
                        {t('Subscribe for updates')}
                    </Button>
                </div>
            </>
        }

    </div>
}
