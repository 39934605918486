import { GaParams } from "core/typings/gaTypes";

export const debugLogHelper = (eventName: string, params: GaParams): void => {
  const stylesTitle = 'color: #555555; font-family:sans-serif; font-size: 14px; font-weight: bold';
  const stylesEvent =
    'color: #8728aa; font-family:sans-serif; font-size: 18px; font-weight: bold; font-style: italic';
  params = Object.entries(params)
    .sort(([a], [b]) => +(a > b) || -1)
    .reduce((accum, [k, v]) => ({ ...accum, [k]: v }), {} as GaParams);

  console.log(`\n`);
  console.log(
    `%cGA4 Event${params.send_to ? ' for counter ID ' + params.send_to : ''}:`,
    stylesTitle,
  );
  console.log(`%c${eventName}`, stylesEvent);
  console.table(params);
  console.log(`\n`);
};
