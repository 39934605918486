import {useTranslation} from "react-i18next";

export const SuccessTier1 = () => {
    const {t} = useTranslation()

    return <div className={'flex flex-col gap-5 justify-center items-center h-full'}>
        <div className={'aspect-square text-brand-primary text-center'}>
            <img src={'/images/capybara-with-heart.svg'} alt={''}/>
        </div>
        <div className={'text-center font-heading text-header-02 font-extrabold max-w-[22.5rem]'}>
            {t('verification.tier1.success.title')}
        </div>
        <div className={'text-center text-body-01 text-label-tertiary max-w-[20.5rem]'}
             dangerouslySetInnerHTML={{__html: t('verification.tier1.success.description')}}>
        </div>
    </div>
}
