import {useMutation} from "@tanstack/react-query";
import {setCountry} from "@capyfast/api";

import {MutationKeys} from "../../../core/constants/queries-keys";

export const useSetCountryMutation = () => {
    const {
        mutate,
        data,
        isError,
        isPending,
        isSuccess,
        reset
    } = useMutation({
        mutationKey: [MutationKeys.SET_COUNTRY],
        mutationFn: (data: Parameters<typeof setCountry>[0]) => setCountry(data)
            .then(response => response.data)
    })


    return {
        result: data ?? null,
        mutate,
        isError,
        isPending,
        isSuccess,
        reset,
    }
}
