import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {en, es, pl, pt, ru, uk} from '@capyfast/wti'
import intervalPlural from 'i18next-intervalplural-postprocessor';

intervalPlural.setOptions({
	// these are the defaults
	intervalSeparator: ';',
	intervalSuffix: '_interval'
});

i18n
	.use(intervalPlural)
	.use(initReactI18next)
	.init({
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false,
		},
		keySeparator: false,
		nsSeparator: false,
		react: {
			useSuspense: false,
			transSupportBasicHtmlNodes: true,
			transKeepBasicHtmlNodesFor: ['br', 'b'],
		},
		returnEmptyString: false,
		resources: {
			en: {
				translation: en,
			},
			es: {
				translation: es,
			},
			pl: {
				translation: pl,
			},
			pt: {
				translation: pt,
			},
			uk: {
				translation: uk
			},
			ru: {
				translation: ru
			}
		},
	});

    export { i18n };
    export default i18n;
