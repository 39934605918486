import {InputsSizes, PopoverVerticalPositions, Select} from "@design-system/ui-kit";
import {i18n} from "i18n";

import { EGaCategories, EGaEvents, EGaTypes } from "core/typings/enums";
import { sendGaEvent } from "core/utils/sendGaEvent";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {LOCALES} from "../../../core/constants/i18n";
import {useLocalization} from "../../../domain/i18n/providers/i18n";
import {getSelectItemByValue, getSingleValueFromSelectItem} from "../../../core/utils/select-helpers";


export const Footer = () => {
    const {t} = useTranslation();
    const {lang, changeLang} = useLocalization();

    const footerLinks = [
        {text: i18n.t("CapyFast"), path: 'https://capyfast.com', analyticsPath: "/capyfast"},
        {text: i18n.t("Privacy Policy"), path: 'https://capyfast.com/privacy-policy/', analyticsPath: "/privacy-policy"},
    ];

    const languages = [
        {node: t('language.EN'), value: LOCALES.EN,},
        {node: t('language.ES'), value: LOCALES.ES,},
        {node: t('language.PL'), value: LOCALES.PL,},
        {node: t('language.PT'), value: LOCALES.PT,},
        {node: t('language.RU'), value: LOCALES.RU,},
        {node: t('language.UA'), value: LOCALES.UK,},
    ];

    return (
        <div className={'flex justify-between items-center gap-4 text-sm text-label-tertiary pb-8'}>
            <div className={'flex gap-8'}>
                {footerLinks.map((link, index) => (
                    <Link target="_blank"
                          key={index}
                          to={link.path}
                          onClick={() => {
                              sendGaEvent(EGaEvents.CLICK_FOOTER, {
                                  event_option1: EGaCategories.FOOTER,
                                  event_option2: EGaTypes.FOOTER,
                                  event_option4: link.text,
                              });
                          }}
                    >
                        {link.text}
                    </Link>
                ))}
            </div>
            <div className={'flex justify-end grow'}>
                <Select
                    onChange={(value) => changeLang(getSingleValueFromSelectItem(value))}
                    dropProps={{
                        position: PopoverVerticalPositions.top,
                    }}
                    inputProps={{
                        size: InputsSizes.L,
                    }}
                    selected={getSelectItemByValue(languages, lang)}
                    items={languages}
                />
            </div>
        </div>
    )

}
