import {sendGaEvent} from "core/utils/sendGaEvent";
import {EGaCategories, EGaEvents, EGaTypes} from "core/typings/enums";
import {PlatformSchema} from "core/store";

import {usePayoutFlow} from "../../../../domain/payout/providers/payout-flow";
import {AutoPayoutInfo} from "../auto-payouts-info/auto-payout-info";
import {PayoutInfo} from "../payout-info/payout-info";

export const Information = ({activePlatform}: {activePlatform?: PlatformSchema }) => {
    const {
        onPayout,
        isNoNewPayout,
        isAutoPayoutEnabled,
    } = usePayoutFlow();

    if(isAutoPayoutEnabled) return <AutoPayoutInfo activePlatform={activePlatform} />;

    return <PayoutInfo onStart={() => {
            onPayout();
            sendGaEvent(EGaEvents.CLICK_GET_ADVANCE_PAYOUT, {
                event_option1: EGaCategories.REQUEST_PAYOUT,
                event_option2: EGaTypes.REQUEST_FOR_AUTO_ADVANCE_PAYOUT,
                event_option4: activePlatform?.title
              });
        }}/>
}
