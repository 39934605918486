import {Button, ButtonSizes, ButtonThemes, Checkbox} from "@design-system/ui-kit";
import {ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import classnames from "classnames";
import {ReactComponent as SelectedIcon} from "assets/svg/interface-icons/selected.svg";
import {ReactComponent as UpMoneyIcon} from "assets/svg/onboarding/modal/up-money.svg";
import {ReactComponent as ClockIcon} from "assets/svg/onboarding/modal/clock.svg";
import {ReactComponent as CheckListIcon} from "assets/svg/onboarding/modal/check-list.svg";
import {ReactComponent as SuccessIcon} from "assets/svg/interface-icons/success-cog.svg";
import {useRecoilValue} from "recoil";
import {useNavigate} from "react-router-dom";
import {OnboardingLegalDocuments, UserLegalDocumentsSchemaType} from "@capyfast/contracts";
import {Trans, useTranslation} from "react-i18next";

import {Modal} from "../../../molecules/modal";
import {useUser} from "../../../../domain/user/providers/user";
import {useSignDocsMutation} from "../../../../domain/user/mutations/sign-docs";
import {useEnableAutoPayoutsMutation} from "../../../../domain/auto-payout/mutations/enable-auto-payouts";
import {instancePayoutClientTypeAtom} from "../../../../core/store";
import {Paths} from "../../../../core/router";
import {sendGaEvent} from "../../../../core/utils/sendGaEvent";
import {EGaCategories, EGaEvents, EGaTypes} from "../../../../core/typings/enums";

const ArrowIcon = ({className}: { className: string }) => {
    return <svg className={className} width="24" height="25" viewBox="0 0 24 25" fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <path d="M8 10.6621L12 14.6621L16 10.6621" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
              strokeLinejoin="round"/>
    </svg>

}

const CardLabel = ({label, content}: {
    label: string,
    content: string
}) => {
    return <div>
        <div className={'text-body-04 text-label-quaternary'}>{label}</div>
        <div className={'text-body-02 text-label-secondary'}>{content}</div>
    </div>
}

const OptionCard = ({title, children, onSelect, isSelected, className, isResult}: {
    title: string,
    children: ReactNode,
    onSelect?: () => unknown,
    isSelected?: boolean,
    isResult?: boolean,
    className?: string
}) => {
    const {t} = useTranslation();

    return <div className={classnames(
        'flex flex-col justify-between rounded-xl border p-5 space-y-4 transition',
        className)}>
        <div className={classnames('text-body-01 font-medium', {'text-center': isResult})}>
            {title}
        </div>
        <div className={''}>
            <div className={classnames('flex gap-5 mb-4', {'justify-around': isResult})}>
                {children}
            </div>
            {!!onSelect
                && <Button fluid
                           onClick={onSelect}
                           size={ButtonSizes.xl}
                           theme={ButtonThemes.interfaceOnsurface}>
                    {isSelected && <SelectedIcon className={'text-brand-primary mr-2'}/>}
                    {isSelected ? t('Selected') : t('Select')}
                </Button>
            }
        </div>
    </div>
}

export const InstantPayoutClient = () => {
    const {t} = useTranslation()
    const instancePayoutClientType = useRecoilValue(instancePayoutClientTypeAtom);
    const [isNeedActivateAuto, setIsNeedActivateAuto] = useState<boolean>(instancePayoutClientType === 'auto')
    const [agree, setAgree] = useState<boolean>(false)
    const [marketing, setMarketing] = useState<boolean>(false)
    const [showTerms, setShowTerms] = useState<boolean>(false)
    const {signDocs} = useUser();
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [isClosed, setIsClosed] = useState<boolean>(false);
    const navigate = useNavigate();


    const {
        signDocumentsAsync,
        isPending,
    } = useSignDocsMutation();
    const {
        mutateAsync: enableAuto,
        isPending: isPendingEnableAuto,
    } = useEnableAutoPayoutsMutation();

    const {
        refetchUserConfig,
    } = useUser();

    const marketingDoc = signDocs.find((i: UserLegalDocumentsSchemaType) => i.key === OnboardingLegalDocuments.CAPYFAST_MARKETING);

    const onSubmit = useCallback(() => {
        const ids = signDocs.filter((i: UserLegalDocumentsSchemaType) => i.is_required).map((i: UserLegalDocumentsSchemaType) => i.id);
        if (marketing) {
            if (marketingDoc) ids.push(marketingDoc.id)
        }

        signDocumentsAsync(ids)
            .then(async () => {
                if (isNeedActivateAuto) {
                    const isAutoResult = await enableAuto();
                    setIsSuccess(true)
                } else setIsSuccess(true);
            });
    }, [enableAuto, isNeedActivateAuto, marketing, signDocs, signDocumentsAsync])

    const onClose = useCallback(() => {
        refetchUserConfig().then(() => {
            setIsClosed(true);
            navigate(Paths.PLATFORM_ADMITAD)
        })
    }, [navigate, refetchUserConfig]);

    const docLinks = useMemo(() => {
        return {
            privacy: signDocs.find((d: UserLegalDocumentsSchemaType) => d.key === OnboardingLegalDocuments.CAPYFAST_PRIVACY)?.file_url ?? '',
            terms: signDocs.find((d: UserLegalDocumentsSchemaType) => d.key === OnboardingLegalDocuments.CAPYFAST_TERMS)?.file_url ?? ''
        }
    }, [signDocs])

    useEffect(() => {
        if (!isSuccess && !isClosed) {
            sendGaEvent(EGaEvents.SHOW_POPUP_IP_TO_CAPYFAST, {
                'event_option1': EGaCategories.WELCOME_PAGE,
                'event_option2': isNeedActivateAuto
                    ? EGaTypes.POPUP_INSTANTPAYOUT_TO_CAPYFAST_USERS_WITH_IP_AUTO
                    : EGaTypes.POPUP_INSTANTPAYOUT_TO_CAPYFAST_USERS_WITHOUT_IP_AUTO,
            })
        }
    }, [isClosed, isNeedActivateAuto, isSuccess]);

    if (isSuccess && !isClosed) {
        return <Modal isShow={true}>
            <div className={'flex flex-col items-center space-y-4'}>
                <div className={'flex justify-center items-center text-brand-primary'}>
                    <SuccessIcon/>
                </div>
                <div className={'text-header-03 font-extrabold text-label-primary text-heading text-center'}>
                    {
                        isNeedActivateAuto
                            ? <Trans>
                                Auto Instant Payout was successfully <span
                                className={'text-brand-primary'}>enabled</span>
                            </Trans>
                            : t('Welcome to CapyFast')
                    }
                </div>
                <OptionCard title={t('You’ve accepted')}
                            isResult={true}
                            className={`w-full max-w-[22.5rem] ${isNeedActivateAuto ? 'bg-background-success' : 'bg-background-tertiary'}`}>
                    <CardLabel label={t('Commission')}
                               content={isNeedActivateAuto
                                   ? t('InstantPayoutClientModal.CommissionWithAuto', {defaultValue: '8%'})
                                   : t('InstantPayoutClientModal.Commission', {defaultValue: '9%'})}/>
                    <CardLabel label={t('Payouts')} content={isNeedActivateAuto ? t('Auto') : t('Manual')}/>
                </OptionCard>

                <div className={'text-body-01 text-label-tertiary text-center w-full max-w-[22.5rem]'}>
                    {isNeedActivateAuto
                        ? t('As soon as 10 USD/EUR/GBP is accumulated, the money will be transferred to your Wallet')
                        : t('In future you can enable auto to save more money and time')}
                </div>

                <div>
                    <Button onClick={onClose}
                            size={ButtonSizes.xl}
                            theme={ButtonThemes.interfaceOnsurface}>
                        {t('Close')}
                    </Button>
                </div>
            </div>
        </Modal>
    }

    return <Modal isShow={!isSuccess}>
        <div className={'space-y-5'}>
            <div className={'flex justify-center'}>
                <img alt={''}
                     className={'max-w-full'}
                     src={'/images/instant-payout-to-capyfast.svg'}/>
            </div>
            <div className={'text-label-secondary text-header-03 font-heading font-extrabold'}>
                {t('Instant Payout is now CapyFast! Accept conditions to continue using it')}
            </div>
            <div className="text-label-secondary text-body-01">
                <Trans i18nKey="CapyfastAllowsYouTo">
                    CapyFast allows you to receive your rewards from Admitad earlier! Actions that were confirmed by
                    advertisers become eligible. Currently available for actions in USD, EUR and GBP.
                    <br/>
                    All your Instant Payouts will be collected in CapyFast Wallet
                </Trans>
            </div>
        </div>
        <div className={''}>
            <div className={'uppercase text-label-quaternary text-body-02 mb-size-0.5 mt-5'}>
                {t('Choose your conditions:')}
            </div>

            <div className={classnames('grid gap-size-0.5', {
                'grid-cols-5': instancePayoutClientType === 'auto',
                'grid-cols-4': instancePayoutClientType !== 'auto',
            })}>
                <OptionCard title={instancePayoutClientType === 'auto'
                    ? t('Save your Instant Payout settings')
                    : t('Use new conditions')}
                            className={classnames({
                                'col-span-3': instancePayoutClientType === 'auto',
                                'col-span-2': instancePayoutClientType !== 'auto',
                                'bg-background-success': isNeedActivateAuto
                            })}
                            onSelect={() => setIsNeedActivateAuto(true)}
                            isSelected={isNeedActivateAuto}>
                    <CardLabel label={t('Commission')} content={t('InstantPayoutClientModal.CommissionWithAuto', {defaultValue: '8%'})}/>
                    <CardLabel label={t('Payouts')} content={t('Auto')}/>
                </OptionCard>

                <OptionCard title={instancePayoutClientType === 'auto'
                    ? t('Change conditions')
                    : t('Save your Instant Payout settings')
                }
                            className={classnames('col-span-2', {'bg-background-tertiary': !isNeedActivateAuto})}
                            onSelect={() => setIsNeedActivateAuto(false)}
                            isSelected={!isNeedActivateAuto}>
                    <CardLabel label={t('Commission')} content={t('InstantPayoutClientModal.Commission', {defaultValue: '9%'})}/>
                    <CardLabel label={t('Payouts')} content={t('Manual')}/>
                </OptionCard>
            </div>
        </div>
        <div
            className={classnames('border-t border-t-interface-sixth px-10 -mx-10 mt-4 ', {'bg-background-secondary': showTerms})}>
            <div
                className={classnames('mb-5 transition-all bg-origin-padding', {'bg-background-secondary': showTerms})}>
                <div className={'py-3 flex items-center cursor-pointer text-body-03 text-label-quaternary'}
                     onClick={() => setShowTerms((prev) => !prev)}>
                    {t('Auto Payout Terms')}
                    <ArrowIcon className={classnames({'rotate-180': showTerms})}/>
                </div>
                {showTerms
                    && <div className={'mb-5 border-b border-b-interface-sixth -mx-10 px-10 pb-5'}>
                        <div className={'text-body-03 font-medium mb-5'}>{t('The process is simple:')}</div>
                        <div className={'space-y-size-1 text-body-03 text-label-secondary'}>
                            <div className={'flex items-center gap-4'}>
                                <div className={'flex-none w-6 aspect-square text-brand-primary'}>
                                    <UpMoneyIcon className={'w-6'}/>
                                </div>
                                {t('As soon as 10 USD/EUR/GBP is accumulated, the rewards will be transferred to your Wallet')}
                            </div>
                            <div className={'flex items-center gap-4'}>
                                <div className={'flex-none w-6 aspect-square text-brand-primary'}>
                                    <ClockIcon className={'w-6'}/>
                                </div>
                                {t('Rewards will be transferred to CapyFast Wallet automatically, without requests in CapyFast')}
                            </div>
                            <div className={'flex items-center gap-4'}>
                                <div className={'flex-none w-6 aspect-square text-brand-primary'}>
                                    <CheckListIcon className={'w-6'}/>
                                </div>
                                {t('You can cancel auto only in 30 days after enabling it. After cancellation you will have 30 days to change your mind')}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
        <div className={'flex flex-col gap-4'}>
            <div className={'flex flex-col gap-size-0.5'}>
                <Checkbox onChange={setAgree}
                          checked={agree}
                          label={<div className="text-body-03 text-label-tertiary">
                              <Trans i18nKey="termsAndPrivacy" components={[
                                  <a href={docLinks['terms']} className="underline" target="_blank" rel="noreferrer"/>,
                                  <a href={docLinks['privacy']} className="underline" target="_blank" rel="noreferrer"/>
                              ]}>
                                  I’ve read and agree with CapyFast Terms and Conditions and Privacy Policy
                              </Trans>
                          </div>}/>
                {!!marketingDoc && <Checkbox onChange={setMarketing}
                                             checked={marketing}
                                             label={<div className="text-body-03 text-label-tertiary">
                                                 <Trans i18nKey="onboarding.documentsCheckboxLabel.capyfast_marketing">
                                                     I agree to receive notifications about special offers, news, and
                                                     products
                                                 </Trans>
                                             </div>}/>}
            </div>

            <Button className={'px-8'}
                    size={ButtonSizes.xl}
                    disabled={!agree}
                    loading={isPending || isPendingEnableAuto}
                    onClick={onSubmit}>
                {t('Join Capyfast')}
            </Button>
        </div>
    </Modal>
}
