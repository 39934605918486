import {useRecoilState, useRecoilValue} from "recoil";
import {useCallback, useEffect} from "react";

import {
    isAcceptTermsAndConditionsAtom,
    isOpenEnabledModalSelector,
    isShowSuccessEnabledScreenAtom
} from "../../../core/store";
import {useEnableAutoPayoutsMutation} from "../mutations/enable-auto-payouts";
import {useVerificationService} from "../../verification/services/verification";
import {Screens} from "../../payout/constants";

export const useEnableAutoPayoutFlow = () => {
    const [isOpen, setIsOpen] = useRecoilState(isOpenEnabledModalSelector);
    const isAccept = useRecoilValue(isAcceptTermsAndConditionsAtom);
    const [, setIsShowSuccess] = useRecoilState(isShowSuccessEnabledScreenAtom);
    const {isNeedVerification, onStartVerification, setOnFinish, setScreen} = useVerificationService();


    const {
        enableAutoPayouts,
        isError,
        isPending,
        isSuccess,
        reset,
    } = useEnableAutoPayoutsMutation();

    const onEnable = useCallback(() => {
        if(isAccept) {
            if (isNeedVerification) {
                setOnFinish( () => {
                    enableAutoPayouts();
                    setScreen(Screens.SUCCESS_ENABLED_AUTO)
                });
                onStartVerification();
            } else {
                enableAutoPayouts();
            }
        }
    }, [enableAutoPayouts, isAccept, isNeedVerification, onStartVerification, setOnFinish])

    useEffect(() => {
        if(isSuccess) {
            setIsShowSuccess(true);
            setIsOpen(false);
        }
    }, [isSuccess, setIsOpen, setIsShowSuccess]);

    return {
        isOpen,
        isAccept,
        isError,
        isPending,
        isSuccess,
        onEnable,
        setIsOpen,
        reset,
    }
}
