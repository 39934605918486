import {HistoryItemSchema} from "@capyfast/contracts";

export enum HistoryItemType {
    AUTO = 'auto',
    MANUAL = 'manual'
}

export const typeKeys: {[key in HistoryItemType]: string} = {
    [HistoryItemType.AUTO]: 'Auto',
    [HistoryItemType.MANUAL]: 'One-time'
}

export const getStatusVariant = (status: HistoryItemSchema['status']) => {
    switch (status) {
        case 'paid':
            return 'success';
        case 'failed':
        // case 'declined':
        //     return 'danger'
        default:
            return 'secondary'
    }
}
