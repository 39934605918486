"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WalletBalancesSchema = void 0;
const zod_1 = require("zod");
exports.WalletBalancesSchema = zod_1.z.object({
    EUR: zod_1.z.number().optional(),
    RUB: zod_1.z.number().optional(),
    USD: zod_1.z.number().optional(),
    INR: zod_1.z.number().optional(),
    UAH: zod_1.z.number().optional(),
    BRL: zod_1.z.number().optional(),
    GBP: zod_1.z.number().optional(),
    PLN: zod_1.z.number().optional(),
    AED: zod_1.z.number().optional(),
    SAR: zod_1.z.number().optional(),
    TRY: zod_1.z.number().optional(),
    MXN: zod_1.z.number().optional(),
    KZT: zod_1.z.number().optional(),
});
