import {ReactComponent as SuccessIcon} from "assets/svg/interface-icons/success.svg";
import {Button, ButtonSizes, ButtonThemes} from "@design-system/ui-kit";
import {Link} from "react-router-dom";
import { sendGaEvent } from "core/utils/sendGaEvent";
import { EGaCategories, EGaEvents, EGaTypes } from "core/typings/enums";
import { PlatformSchema } from "core/store";
import { useEffect } from "react";

import {Paths} from "../../../../core/router";
import {usePayoutFlow} from "../../../../domain/payout/providers/payout-flow";
import { Trans, useTranslation } from "react-i18next";
import {ENV} from "../../../../core/environment";

export const SuccessPayout = ({activePlatform}: {activePlatform?:PlatformSchema }) => {

    useEffect(() => {
            sendGaEvent(EGaEvents.SHOW_PAYOUT_MONEY_TRANSFERRED_SUCCES, {
                event_option1: EGaCategories.REQUEST_PAYOUT,
                event_option2: EGaTypes.PAYOUT_RECEIVED,
                event_option4: activePlatform?.title
                });
    }, [activePlatform?.title])
    const {t} = useTranslation()

    const {
        resetPayoutFlow,
    } = usePayoutFlow();
    return <div className={'flex flex-col gap-5 justify-center items-center h-full'}>
        <div className={'w-20 aspect-square text-brand-primary'}><SuccessIcon/></div>
        <div className={'text-center font-heading text-header-03 font-extrabold max-w-[22.5rem]'}>{t('Money transferred')}</div>
        <div className="text-center text-body-01 text-label-tertiary max-w-[20.5rem]">
        <Trans i18nKey="nextPaymentsNotice">
            You will be able to see the next payments&nbsp;in&nbsp;the&nbsp;
            <Link onClick={() => {
                sendGaEvent(EGaEvents.CLICK_HISTORY, {
                    event_option1: EGaCategories.REQUEST_PAYOUT,
                    event_option2: EGaTypes.PAYOUT_RECEIVED,
                    event_option4: activePlatform?.title
                    });
            }} to={Paths.HISTORY}
            className="underline"
            >
            history
            </Link>.
        </Trans>
        </div>
        <div className={'flex gap-1 justify-center'}>
            <Button size={ButtonSizes.xl}
                    theme={ButtonThemes.ghostFill}
                    onClick={() => {
                        sendGaEvent(EGaEvents.CLICK_OPEN_WALLET, {
                            event_option1: EGaCategories.REQUEST_PAYOUT,
                            event_option2: EGaTypes.PAYOUT_RECEIVED,
                            event_option4: activePlatform?.title
                          })
                    }}
                    href={ENV.WALLET_LINK}
                    target={'_blank'}
            >{t('Open Wallet')}</Button>
            <Button size={ButtonSizes.xl} onClick={() => {
                resetPayoutFlow()
                sendGaEvent(EGaEvents.CLICK_CLOSE, {
                    event_option1: EGaCategories.REQUEST_PAYOUT,
                    event_option2: EGaTypes.PAYOUT_RECEIVED,
                    event_option4: activePlatform?.title
                  })
            }}>{t('Close')}</Button>
        </div>
    </div>
}
