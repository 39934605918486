import {Button, ButtonSizes} from "@design-system/ui-kit";

import {useUser} from "../../../../domain/user/providers/user";
import { sendGaEvent } from "core/utils/sendGaEvent";
import { EGaCategories, EGaEvents, EGaTypes } from "core/typings/enums";
import { useTranslation } from "react-i18next";

type WelcomeProps = {
}

export const Finish = ({}: WelcomeProps) => {
    const {t} = useTranslation()
    const {refetchUserConfig, setIsNeedSignDocs} = useUser();

    return <div className={'flex flex-col justify-center items-center h-full'}>
        <div
            className={'w-10 mb-6 mx-auto aspect-square rounded-full border-s3 border-brand-primary text-brand-primary flex justify-center items-center font-heading text-[1.4rem]'}>
            3
        </div>
        <img src={'/images/capybara-onboarding-finish.svg'} alt={'Finish image'}/>
        <div className={'flex flex-col justify-center items-center space-y-6 max-w-[37.5rem]'}>
            <div className={'text-header-03 font-heading font-extrabold text-center'}>{t('Congratulations! Welcome to CapyFast!')}
            </div>
            <div className={'text-body-01 text-label-tertiary text-center'}>{t('Start receiving money faster now!')}</div>
            <div>
                <Button onClick={() => {
                    refetchUserConfig();
                    setIsNeedSignDocs(false);
                    sendGaEvent(EGaEvents.CLICK_GO_TO_DASHBOARD, {
                        event_option1: EGaCategories.WELCOME_PAGE,
                        event_option2: EGaTypes.STEP_THREE_DOCUMENTS,
                      });
                }} size={ButtonSizes.xl}>{t('Go to dashboard')}</Button>
            </div>
        </div>
    </div>
}
