import {Button, ButtonSizes, ButtonThemes, Select} from "@design-system/ui-kit";
import {Trans, useTranslation} from "react-i18next";
import {useEffect, useMemo, useState} from "react";
import {enCountries, esCountries, plCountries, ptCountries, ruCountries, ukCountries} from "@capyfast/wti";
import get from "lodash/get";
import {useRecoilState} from "recoil";
import {VerificationStatuses} from "@capyfast/contracts";

import {useCountriesList} from "../../../../../domain/verification/queries/countries";
import {getSelectItemByValue, getSingleValueFromSelectItem} from "../../../../../core/utils/select-helpers";
import {Modal} from "../../../../molecules/modal";
import {useSetCountryMutation} from "../../../../../domain/verification/mutations/set-country";
import {Tier1StateAtom, Tier1States} from "../../../../../core/store";
import {useGetTierStatusQuery} from "../../../../../domain/verification/queries/get-tier-status";

type SelectLocationProps = {
    onFinish?: () => unknown,
    onBack?: () => unknown
}

export const SelectLocation = ({onFinish, onBack}: SelectLocationProps) => {
    const {t, i18n} = useTranslation();

    const [, setTier1State] = useRecoilState(Tier1StateAtom);
    const [country, setCountry] = useState<string | null>(null);
    const [region, setRegion] = useState<string | null>(null);
    const [isShowConfirm, setIsShowConfirm] = useState<boolean>(false);

    const {data: countries, isLoading} = useCountriesList();
    const {mutate, isPending, isSuccess, isError, result} = useSetCountryMutation();

    const {data: tierState} = useGetTierStatusQuery({enabled: true, tier: '1'});

    useEffect(() => {
        switch(tierState?.status){
            case VerificationStatuses.FAILED:
                setTier1State(Tier1States.FAILED)
                break;
            case VerificationStatuses.NEED_ACTION:
                setTier1State(Tier1States.NEED_ACTION)
                break;
        }
    }, [setTier1State, tierState]);

    useEffect(() => {
        switch (result?.status) {
            case VerificationStatuses.SUCCESS:
                setTier1State(Tier1States.SUCCESS)
                setTimeout(() => {
                    onFinish?.();
                }, 3000)
                break;
            case VerificationStatuses.FAILED:
                setTier1State(Tier1States.FAILED)
                break;
            case VerificationStatuses.NEED_ACTION:
                setTier1State(Tier1States.NEED_ACTION)
                break;
        }
    }, [onFinish, result, setTier1State]);


    useEffect(() => {
        isError && setTier1State(Tier1States.NEED_ACTION)
    }, [isError, setTier1State]);

    const countriesLocales = useMemo(() => {
        switch (i18n.language) {
            case 'en':
            default:
                return enCountries
            case 'pl':
                return plCountries
            case 'pt':
                return ptCountries
            case 'es':
                return esCountries
            case 'ua':
            case 'uk':
                return ukCountries
            case 'ru':
                return ruCountries
        }
    }, [i18n.language])

    const countriesOptions = useMemo(() => {
        if (!countries) return [];
        return countries.map((c) => ({value: c.alpha_2_code, node: get(countriesLocales, c.alpha_2_code, c.name)}))
    }, [countries, countriesLocales]);

    const regionsOptions = useMemo(() => {
        if (country && countries) {
            const c = countries.find((i) => i.alpha_2_code === country);
            return c?.regions.map((r) => ({node: r, value: r})) ?? [];
        }
        return []
    }, [countries, country]);

    const isNeedRegion = !!regionsOptions?.length;
    const isValid = isNeedRegion ? !!country && !!region : !!country

    const onSubmit = () => {
        if (country) mutate({
                alpha_2_code: country,
                region: isNeedRegion ? region : undefined
            }
        );
    }

    useEffect(() => {
        if (isSuccess) {
            setIsShowConfirm(false);
        }
    }, [isSuccess])

    return <div className={'h-full flex flex-col justify-between'}>
        <div className={'space-y-size-3'}>
            <div className={'flex justify-center'}>
                <img src={'/images/capybara-search.svg'} alt={''}/>
            </div>
            <div className={'font-heading text-header-03 text-label-secondary font-extrabold'}>
                <Trans i18nKey={'verification.tier1.title'}
                       components={[<span className={'text-brand-primary'}/>]}>
                    Hi, you're doing great! For withdrawals, we have to ask you to verify who you are.
                </Trans>

            </div>
            <div className={'prose text-body-02 leading-6'}
                 dangerouslySetInnerHTML={{__html: t('verification.tier1.description')}}/>
            <Select className={'w-full'}
                    hasSearch={true}
                    dropProps={{
                        maxDropHeight: 320
                    }}
                    selected={getSelectItemByValue(countriesOptions, country)}
                    onChange={(v) => {
                        v[0] && setCountry(getSingleValueFromSelectItem(v))
                    }}
                    items={countriesOptions}
                    inputProps={{
                        disabled: isLoading,
                        placeholder: t('verification.tier1.chooseYourCountry')
                    }}/>
            {!!regionsOptions?.length
                && <Select className={'w-full'}
                           selected={getSelectItemByValue(regionsOptions, region)}
                           hasSearch={true}
                           onChange={(v) => {
                               v[0] && setRegion(getSingleValueFromSelectItem(v))
                           }}
                           dropProps={{
                               maxDropHeight: 320
                           }}
                           items={regionsOptions}
                           inputProps={{
                               disabled: isLoading,
                               placeholder: t('verification.tier1.chooseYourState')
                           }}/>}
        </div>
        <div className={'flex justify-between items-center mt-size-3'}>
            <Button size={ButtonSizes.xl}
                    onClick={onBack}
                    disabled={false}
                    theme={ButtonThemes.interfaceOnsurface}>
                {t('Back')}
            </Button>
            <Button size={ButtonSizes.xl}
                    disabled={!isValid}
                    loading={isLoading}
                    onClick={() => {
                        setIsShowConfirm(true)
                    }}>
                {t('Next')}
            </Button>
        </div>
        <Modal isShow={isShowConfirm} onClose={() => setIsShowConfirm(false)}>
            <div className={'space-y-6'}>
                <div
                    className={'text-header-03 text-label-primary font-heading font-extrabold'}>{t('verification.tier1.confirmModal.title')}</div>
                <div className={'prose text-body-01 text-label-tertiary leading-6'}
                     dangerouslySetInnerHTML={{__html: t('verification.tier1.confirmModal.description')}}/>
                <div className={'py-6 px-size-3 grid grid-cols-2 rounded bg-background-tertiary text-label-tertiary'}>
                    <div
                        className={'text-body-01'}>{t('verification.tier1.confirmModal.Country')}: {get(countriesLocales, country ?? '', country)}</div>
                    {!!region
                        && isNeedRegion
                        && <div className={'text-body-01'}>{t('verification.tier1.confirmModal.State')}: {region}</div>}
                </div>
            </div>
            <div className={'flex justify-between mt-size-3'}>
                <Button size={ButtonSizes.xl}
                        onClick={() => setIsShowConfirm(false)}
                        theme={ButtonThemes.interfaceOnsurface}>
                    {t('Back')}
                </Button>
                <Button size={ButtonSizes.xl} onClick={onSubmit} loading={isPending}>
                    {t('verification.tier1.confirmModal.confirmButton')}
                </Button>
            </div>
        </Modal>
    </div>
}
