const legacySUIDMask = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';

export const getTimeStamp = (): string => {
  const now = new Date();
  const tzo = -now.getTimezoneOffset();
  const dif = tzo >= 0 ? '+' : '-';

  return (
    now.getFullYear() +
    '-' +
    padNumber(now.getMonth() + 1) +
    '-' +
    padNumber(now.getDate()) +
    'T' +
    padNumber(now.getHours()) +
    ':' +
    padNumber(now.getMinutes()) +
    ':' +
    padNumber(now.getSeconds()) +
    '.' +
    padNumber(now.getMilliseconds()) +
    dif +
    padNumber(tzo / 60) +
    ':' +
    padNumber(tzo % 60)
  );
};

export const padNumber = (number: number): string => {
  const int = Math.abs(Math.floor(number));
  return `${int}`.padStart(2, '0');
};

export const getClientId = (): string | undefined => {
  let match = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)');
  const raw = match ? decodeURIComponent(match[1]) : null;
  if (raw) {
    match = raw.match(/(\d{1,29}\.\d+)$/);
  }
  const gacid = match ? match[1] : null;
  if (gacid) {
    return gacid;
  }
};

export const getDateTimeGreenwich = (): string => {
  const date = new Date();

  // returns UTC (=GMT) Hour of the date
  const hour = date.getUTCHours();

  // returns UTC (=GMT) Minutes of the date
  const minute = date.getUTCMinutes();

  // returns UTC (=GMT) Seconds of the date
  const seconds = date.getUTCSeconds();

  // returns UTC (=GMT) year of the date
  const year = date.getUTCFullYear();

  // returns UTC (=GMT) month (0-11)
  //    0 is January, 11 is December
  const month = date.getUTCMonth();

  // returns UTC (=GMT) day of the month (1-31)
  const day = date.getUTCDate();

  return `${year}-${month + 1}-${day} ${hour}:${minute}:${seconds}`;
};

export const getUrlTags = (): string => {
  if (document.URL.split('?')[1] !== undefined) {
    return document.URL.split('?')[1];
  } else if (document.URL.split('#')[1] !== undefined) {
    return document.URL.split('#')[1];
  } else {
    return 'undefined';
  }
};
export const generateSUID = (): string => {
  return legacySUIDMask.replace(/[xy]/g, (symbol: string) => {
    const randomNumber = (Math.random() * 16) | 0;
    const preparedRandomNumber = symbol === 'x' ? randomNumber : (randomNumber & 0x3) | 0x8;
    return preparedRandomNumber.toString(16);
  });
};

export const referrer = (): string => {
  return document.referrer || 'direct none';
};
