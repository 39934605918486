import {Button, ButtonSizes, ButtonThemes} from "@design-system/ui-kit";
import {useRecoilState, useRecoilValue} from "recoil";
import {sendGaEvent} from "core/utils/sendGaEvent";
import {EGaCategories, EGaEvents, EGaTypes} from "core/typings/enums";
import {Trans, useTranslation} from "react-i18next";

import {useApproximateAmounts} from "../../../../domain/approximate-amounts/queries/approximate-amounts";
import {isAcceptTermsAndConditionsAtom, isAutoPayoutSelector, PlatformSchema} from "../../../../core/store";
import {ConditionsOfAutoPayout} from "../conditions-of-auto-payout";
import {AcceptTermsAndConditions} from "../accept-terms-and-conditions/accept-terms-and-conditions";
import {Calculation} from "../calculation/calculation";
import {CommissionLabel} from "../commission-label/commission-label";
import {SomethingWentWrong} from "../../@errors/something-went-wrong/something-went-wrong";

type OneTimeInfoProps = {
    onStart?: () => unknown,
    activePlatform?: PlatformSchema,
}
export const PayoutInfo = ({onStart, activePlatform}: OneTimeInfoProps) => {
    const {t} = useTranslation()
    const [isAutoPayout, setAutoPayout] = useRecoilState(isAutoPayoutSelector);
    const isAccept = useRecoilValue(isAcceptTermsAndConditionsAtom)
    const disabled = !onStart

    const {
        amounts,
        isFetching,
        isLoading,
        isError
    } = useApproximateAmounts();

    if (isError) return <div className={'min-h-full flex flex-col justify-center'}><SomethingWentWrong/></div>

    return <div className={`flex flex-col justify-between h-full ${disabled ? 'pointer-events-none opacity-50' : ''}`}>
        {isAutoPayout
            ? <div>
                <div className="text-header-03 text-label-primary font-heading font-extrabold mb-size-3">
                <Trans i18nKey="requestForAutoAdvance">
                    Request for <span className="text-brand-primary">Auto Advance Payout</span>
                </Trans>
            </div>
                <div className={'mt-size-3 text-body-02 text-label-secondary'}>
                    {t('As soon as the advertiser confirms the actions, we will transfer the rewards to your Wallet.')}
                </div>
            </div>
            : <div>
                <div className={'text-header-03 text-label-primary font-heading font-extrabold mb-size-3'}>
                    {t('Request for One-time Advance Payout')}
                </div>
                <div className={'mt-size-3 text-body-02 text-label-secondary'}>
                    {t('As soon as the advertiser confirms the actions, we will transfer the rewards to your Wallet.')}
                </div>
            </div>
        }
        <div>
            <Calculation amounts={amounts}
                         isLoading={isLoading || isFetching}
                         className={'my-size-3'}
                         textClassName={isAutoPayout ? 'text-brand-primary' : undefined}
            />

        </div>
        <div className={'mt-size-3'}>
            <CommissionLabel
                className={'mb-size-0.5'}
                isAuto={isAutoPayout}
                texts={{
                    isAuto: t(`You have fixed 9% fee, 7% if you enable Auto`),
                    isManual: t(`You have a fixed 7% fee when enabling Auto`),
                    saveMore:     <Trans i18nKey="saveMoreActivation">
                                    Save more by<br /> activating Auto
                                </Trans>
                }}/>
            <ConditionsOfAutoPayout/>
        </div>
        <div className={'mt-size-3'}>
            <AcceptTermsAndConditions platform={'admitad'} eventOption2={EGaTypes.NO_NEW_PAYOUTS} />
        </div>
        {!disabled && <div className={'flex justify-between mt-5'}>
            <Button onClick={onStart}
                    disabled={!isAccept}
                    size={ButtonSizes.xl}>
                {t('Get Advance Payout')}
            </Button>
            <Button onClick={() => {
                sendGaEvent(isAutoPayout ? EGaEvents.CLICK_I_WANT_ONE_TIME : EGaEvents.CLICK_ACTIVATE_AUTO_PAYOUT, {
                    // Поменять event_option2 на EGaTypes.NO_NEW_PAYOUTS если нету денег у пользователя и isAutoPayout
                    event_option1: EGaCategories.REQUEST_PAYOUT,
                    event_option2: EGaTypes.REQUEST_FOR_AUTO_ADVANCE_PAYOUT,
                    event_option4: activePlatform?.title
                  });
                  setAutoPayout((prev) => !prev);
            }}
                    theme={ButtonThemes.ghostFill}
                    size={ButtonSizes.xl}>{isAutoPayout ? t('Get one-time') : t('Get Auto Payout')}</Button>
        </div>}
    </div>
}
