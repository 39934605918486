import {useRecoilState} from "recoil";

import {Tier1StateAtom, Tier1States} from "../../../../core/store";

import {WebSdk} from "./web-sdk";
import {SuccessTier1} from "./success";
import {FailedTier1} from "./failed";
import {NeedActionTier1} from "./need_action";
import {SelectLocation} from "./select-location";

type Tier1Props = {
    onCancel: () => unknown,
    onFinish: Parameters<typeof WebSdk>[0]['onFinish']
}

export const Tier1 = ({onFinish, onCancel}: Tier1Props) => {
    const [state, setState] = useRecoilState(Tier1StateAtom)

    switch(state){
        case Tier1States.SDK:
            return <SelectLocation onFinish={onFinish} onBack={onCancel}/>
        case Tier1States.SUCCESS:
            return <SuccessTier1/>
        case Tier1States.FAILED:
            return <FailedTier1/>
        case Tier1States.NEED_ACTION:
            return <NeedActionTier1/>
    }
}
