import {Button, ButtonSizes, ButtonThemes} from "@design-system/ui-kit";
import { EGaCategories, EGaEvents, EGaTypes } from "core/typings/enums";
import { sendGaEvent } from "core/utils/sendGaEvent";
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

type Tier3Props = {
    onBack: () => unknown
}
export const Tier3 = ({onBack}:Tier3Props) => {
    const {t} = useTranslation()


    useEffect(() => {
        sendGaEvent(EGaEvents.SHOW_VERIFICATION_REQUIRED, {
            event_option1: EGaCategories.VERIFICATION,
            event_option2: EGaTypes.TEN_K_LOAN,
            });

}, [])

    return <div className={'flex flex-col justify-center items-center bg-background-secondary w-full h-full py-16'}>
        <img src={'/images/capybara-mail.svg'} alt={'Email'}/>
        <div className={'py-6 flex flex-col justify-center items-center w-full  max-w-30rem'}>
        <Trans>
            Verification is required, we will <span className={'text-brand-primary'}>contact</span> you shortly
        </Trans>
            <div className={'text-body-01 text-label-tertiary text-center'}>
                {t('Just a few questions to let you enjoy all the benefits of our product')}
            </div>

        </div>
        <Button size={ButtonSizes.xl}
                onClick={() => {
                    sendGaEvent(EGaEvents.CLICK_BACK, {
                        event_option1: EGaCategories.VERIFICATION,
                        event_option2: EGaTypes.TEN_K_LOAN,
                        });
                    onBack()
                }}
                theme={ButtonThemes.interfaceOnsurface}>
            {t('Back')}
        </Button>
    </div>
}
