import {formatAmount} from "@capyfast/utils";
import {ApproximateAmountsSchema} from "@capyfast/contracts";
import classnames from "classnames";
import {useRecoilValue} from "recoil";
import {useTranslation} from "react-i18next";
import {useMemo} from "react";
import {ScrollShadow} from "@nextui-org/scroll-shadow";
import {Loader} from "@design-system/ui-kit";

import {isAutoPayoutSelector} from "../../../../core/store";
import {useGetStatisticsQuery} from "../../../../domain/statistics/queries/get-statistics";

type CalculationProps = {
    className?: string
    amounts: ApproximateAmountsSchema[],
    textClassName?: string,
    isLoading?: boolean
}


const LoadingSkeleton = ({className}: { className?: string }) => {
    const {t} = useTranslation()

    return <div className={classnames('flex gap-6 animate-pulse', className)}>
        <div className={'space-y-[0.38rem] flex flex-col'}>
            <div className={'text-body-03 text-label-secondary'}>{t('Payment amounts:')}</div>
            <div
                className={`text-label-quaternary bg-label-quaternary text-header-03 font-heading font-bold rounded`}>
                0
            </div>
            <div
                className={`w-2/3 text-label-quaternary bg-label-quaternary text-header-03 font-heading font-bold rounded`}>
                0
            </div>
            <div
                className={`text-label-quaternary bg-label-quaternary text-header-03 font-heading font-bold rounded`}>
                0
            </div>
            <div className={'pt-1'}>
                <div className={`text-body-03 text-brand-primary `}>{t('Saved time')} </div>
                <div className={`text-brand-primary bg-brand-primary rounded text-header-03 font-heading font-bold`}>
                    {t('{{value}} day', {value: 56})}
                </div>
            </div>
        </div>
        <div className={'space-y-[0.38rem]'}>
            <div className={'text-body-03 text-label-quaternary'}>{t('Save with auto')}</div>
            <div
                className={`text-label-quaternary bg-label-quaternary text-header-03 font-heading font-bold rounded`}>
                0
            </div>
            <div
                className={`w-4/5 text-label-quaternary bg-label-quaternary text-header-03 font-heading font-bold rounded`}>
                0
            </div>
            <div
                className={`w-11/12 text-label-quaternary bg-label-quaternary text-header-03 font-heading font-bold rounded`}>
                0
            </div>
        </div>
    </div>
}

export const Calculation = ({amounts, className, textClassName = 'text-label-sixth', isLoading}: CalculationProps) => {
    const {t} = useTranslation()

    const isAutoPayout = useRecoilValue(isAutoPayoutSelector);
    const {statisticData, isLoading: isLoadingStatistics} = useGetStatisticsQuery();

    const isSkeleton = isLoadingStatistics || isLoading

    const classes = useMemo(() => {

        if (amounts.length < 4) return {
            col: 'py-size-0.5',
            name: 'text-body-03',
            code: 'text-body-01',
            value: 'text-header-06'
        }
        if (amounts.length < 9) return {
            col: 'py-[0.44rem]',
            name: 'text-body-04',
            code: 'text-body-03',
            value: 'text-body-02'
        }
        return {
            col: 'py-[0.19rem]',
            name: 'text-body-04',
            code: 'text-body-03',
            value: 'text-body-03'
        }

    }, [amounts.length])

    return <div className={classnames('gap-6', className)}>
        <div className={'w-full grid grid-cols-3 items-center  border-b'}>
            <div className={`py-[0.5rem] pl-size-0.5  text-body-03 text-label-quaternary`}>
                {t('currenciesList.Currency')}
            </div>
            <div
                className={`py-[0.5rem] text-right text-body-03 text-label-quaternary`}>
                {t('currenciesList.MaximumAvailable')}
            </div>
            <div
                className={`py-[0.5rem] text-right pr-size-0.5  text-body-03 text-label-quaternary`}>
                {t('currenciesList.SavingsWithAuto')}
            </div>
        </div>
        <ScrollShadow className={'max-h-[440px]'} hideScrollBar>
            {isSkeleton && <div className={'w-full h-32 flex items-center justify-center'}><Loader/></div>}
            {!isSkeleton && amounts.map((amount) => {
                return <div className={`${classes.col} w-full grid grid-cols-3 gap-4 items-center border-b`}
                            key={amount.final_amount + amount.currency_code}>
                    <div className={`flex gap-2 items-center pl-size-0.5`}>
                        <div
                            className={`${classes.code} text-label-tertiary font-medium`}>{amount.currency_code}</div>
                        <div
                            className={`${classes.name} text-label-fifth`}>{t(`currenciesList.currenciesName.${amount.currency_code}`, {defaultValue: ''})}</div>
                    </div>
                    <div
                        className={`${classes.value} text-right text-label-secondary font-heading font-bold`}>
                        {formatAmount(isAutoPayout
                            ? amount.final_amount
                            : amount.manual_final_amount)}
                    </div>
                    <div
                        className={`${textClassName} ${classes.value} text-right pr-size-0.5 font-heading font-bold`}>
                        {formatAmount(isAutoPayout
                            ? Number(amount.final_amount) - Number(amount.manual_final_amount)
                            : '0')}
                    </div>
                </div>
            })}
        </ScrollShadow>

        <div className={'w-full grid grid-cols-3 items-center  border-b'}>
            <div
                className={`${classes.col} col-span-2 pl-size-0.5 text-body-03 text-label-quaternary ${textClassName}`}>
                {t('Saved time')}
            </div>
            <div
                className={`${classes.col} text-right text-brand-primary text-header-06 font-heading font-bold ${textClassName} pr-size-0.5`}>
                {t('balancesInfo.savedDays', {count: statisticData?.saved_time_with_auto ?? 0})}
            </div>
        </div>
    </div>
}
