import ReactGA from 'react-ga4';
import { GaEvents } from 'core/typings/gaTypes';
import { GA_IDS } from 'core/constants/trackerIds';
import { ENV } from 'core/environment';

import { getGaParams } from './getGaParams';
import { debugLogHelper } from './gaDebugLog';

const debugMode = ENV.IS_DEV === 'true';

export const sendGaEvent = (eventName: string, eventOptions: GaEvents = {} as GaEvents) => {
  const ga4Params = { ...getGaParams(), ...eventOptions };

  if (debugMode) {
    debugLogHelper(eventName, ga4Params);
  } else {
    try {
      GA_IDS.filter((id) => !!id).forEach((id) => {
        ReactGA.event(eventName, { send_to: id, ...ga4Params });
      });
    } catch (error) {
      console.error('Caught error while sending GA4', error);
    }
  }
};
