import {GaParams} from 'core/typings/gaTypes';
import {decodeJWT} from "@capyfast/utils";

import {LocalStorageKeys} from "../constants/local-storage-keys";

import {generateSUID, getClientId, getDateTimeGreenwich, getTimeStamp, getUrlTags, referrer} from './ga';

const getKeycloakID = () => {
  const tokenData = window.localStorage.getItem('token');

  if(!tokenData) return null;

  try{
    const parsedData = JSON.parse(tokenData)
    if(parsedData.value) {
      const payload = decodeJWT(parsedData.value)
      return payload?.sso_id ?? null;
    }
  }catch (e){
    return null;
  }
}

export const getGaParams = (): GaParams => {
  return {
    event_option1: 'undefined',
    event_option2: 'undefined',
    event_option3: 'capyfast',
    event_option4: 'undefined',

    hit_id: getTimeStamp(),
    uu_id: generateSUID(),
    uu_id_2: generateSUID(),
    datetime_greenwich: getDateTimeGreenwich(),
    datetime_greenwich_2: getDateTimeGreenwich(),

    client_id_ga: getClientId() + '.',
    program_id: 'undefined',
    advertiser_id: 'undefined',
    advcampaign_id: 'undefined',
    publisher_id: localStorage.getItem('user_id') || 'undefined',
    keycloak_id: localStorage.getItem(LocalStorageKeys.SSO_ID) || 'undefined',
    website_id: 'undefined',
    locale: localStorage.getItem(LocalStorageKeys.LANGUAGE) ?? 'en',
    page_params: 'undefined',
    url_tags: getUrlTags(),
    referrer_without_login_page: referrer(),
    ab_test: 'undefined',
    sendbox_id: 'undefined',

    user_id: localStorage.getItem('user_id') || undefined,
  };
};
