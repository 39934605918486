import {EGaCategories, EGaEvents, EGaTypes } from "core/typings/enums";
import { sendGaEvent } from "core/utils/sendGaEvent";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import {ServiceNotAvailable} from "../../../@errors/service-not-available/service-not-available";

export const FailedTier1 = () => {

    useEffect(() => {
            sendGaEvent(EGaEvents.SHOW_SOMETHING_WENT_WRONG, {
                event_option1: EGaCategories.VERIFICATION,
                event_option2: EGaTypes.SOMETHING_WENT_WRONG,
                });

    }, [])

    const {t} = useTranslation()

    return <ServiceNotAvailable className={'h-full'}
                                title={t('verification.tier1.serviceNotAvailable.title')}
                                description={t('verification.tier1.serviceNotAvailable.description')}/>
}
