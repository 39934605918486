import React from "react";
import {useNavigate} from "react-router-dom";
import classnames from "classnames";
import {useTranslation} from "react-i18next";

import {AsideItemsType} from "../aside.types";

import Style from './item.module.scss'


type AsideItemProps = {
    item: AsideItemsType[0],
    onPress?: (item: AsideItemProps["item"]) => unknown,
    className?: string,
}
export const AsideItem = ({item, onPress, className}: AsideItemProps) => {
    const navigate = useNavigate();
    const {t} = useTranslation();

    return <div className={classnames(`${Style.item} text-label-tertiary flex w-full flex-col items-center justify-center gap-1 rounded-2xl`, className)}
                onClick={() => {
                    onPress?.(item);
                    if(item.target === '_blank') window.open(item.link, '_blank')
                    else item.link && navigate(item.link);
                }}>
        {item.icon}
        {/* t('aside.home.title') */}
        {/* t('aside.history.title') */}
        {/* t('aside.wallet.title') */}
        {/* t('aside.help.title') */}
        {item.title && <div className={'text-center text-'}>{t('aside.' + item.key + '.title', {defaultValue: item.title})}</div>}
    </div>
}
