import {Button, ButtonSizes, ButtonThemes} from "@design-system/ui-kit";
import {useRecoilValue} from "recoil";
import { sendGaEvent } from "core/utils/sendGaEvent";
import { EGaCategories, EGaEvents, EGaTypes } from "core/typings/enums";
import { useEffect } from "react";
import {Trans, useTranslation} from "react-i18next";

import {Modal} from "../../../molecules/modal";
import {useDisableAutoPayoutFlow} from "../../../../domain/auto-payout/services/disable";
import {disabledAutoPayoutAvailableSelector, PlatformSchema} from "../../../../core/store";

export const DisableAutoPayoutModal = ({activePlatform}: {activePlatform?: PlatformSchema}) => {
    const {t} = useTranslation()
    const {
        isOpen,
        isSuccess,
        isPending,
        isError,
        isDisableAllowed,
        setIsOpen,
        isShowConfirmation,
        onCancelConfirmation,
        onStartDisabled,
        onDisable,
    } = useDisableAutoPayoutFlow();
    const daysToDisableAvailable = useRecoilValue(disabledAutoPayoutAvailableSelector);

    useEffect(() => {
        if (isOpen) {
            sendGaEvent(EGaEvents.SHOW_DISABLE_POPUP, {
                event_option1: EGaCategories.AUTO_ADVANCE_PAYOUT_ENABLED,
                event_option2: !isDisableAllowed ? EGaTypes.DISABLE_BUTTON_INACTIVE : EGaTypes.DISABLE_BUTTON_ACTIVE,
                event_option4: activePlatform?.title
                });
        }
    }, [isOpen, isDisableAllowed, activePlatform?.title])

    return <><Modal isShow={isOpen} onClose={() => setIsOpen(false)}>
        <div className={'space-y-size-3'}>
            <div>
                <div className={'text-label-secondary text-header-05 font-bold font-heading mb-size-0.5'}>
                    {isDisableAllowed
                        ? t('Disabling Auto Advance Payout is available')
                        : t('Disabling Auto Advance Payout is not yet available')}
                </div>
                <div className={'text-label-tertiary text-body-02'}>
                    {isDisableAllowed
                        ? t('delayAfterEnabling', `30-days of delay after enabling Auto-payout is over. 
                        We give you another 30-days Auto-payout time to rethink your decision.`)
                        : t('disableAutoAdvance','You can disable Auto Advance Payout after {{daysToDisableAvailable}} days. Please note, disabling will lead to a change of terms:', { daysToDisableAvailable })}
                </div>
            </div>
            <div className={'border-s3 rounded-xl border-interface-seventh space-y-size-0.5 p-6'}>
                {isDisableAllowed &&
                    <div className={'uppercase text-body-03 text-label-fifth'}>{t('Remind you once again that:')}</div>}
                <div className={'text-body-02 flex gap-size-2 items-center'}>
                    <div className={'h-[.5rem] aspect-square bg-label-danger rounded-full'}></div>
                    <div>{t('Commission for One-time Advance Payout is')} <b className={'font-extrabold'}>2% {t('higher')}</b></div>
                </div>
                <div className={'text-body-02 flex gap-size-2 items-center'}>
                    <div className={'h-[.5rem] aspect-square bg-label-danger rounded-full'}></div>
                    <div>
                        <Trans i18nKey="advertiserPaymentDelay">
                            It will take <b className="font-extrabold">longer to get paid</b> by advertisers: they have to settle up with the company first.
                        </Trans>
                    </div>
                </div>
            </div>
            <div className={'flex justify-between items-center'}>
                <div className={'flex items-center gap-4'}>
                    <Button size={ButtonSizes.xl}
                            onClick={() => {
                                sendGaEvent(EGaEvents.CLICK_DISABLE_AUTO_ADVANCE_PAYOUT, {
                                    event_option1: EGaCategories.AUTO_ADVANCE_PAYOUT_ENABLED,
                                    event_option2: EGaTypes.DISABLE_BUTTON_ACTIVE,
                                    event_option4: activePlatform?.title
                                    });
                                onStartDisabled();
                            }}
                            loading={isPending}
                            disabled={!isDisableAllowed}>
                        {t('Disable')}
                    </Button>
                    <div className={'text-label-quaternary text-body-03 max-w-[12.5rem]'}>
                        {isDisableAllowed
                            ? t('You may turn off Auto-payout. This process will take 30 days')
                            : t(`You may turn off Auto-payout only in {{daysToDisableAvailable}} days`, {daysToDisableAvailable})}
                    </div>
                </div>
                <div>
                    <Button theme={ButtonThemes.interfaceOnsurface}
                            onClick={() => {
                                setIsOpen(false);
                                sendGaEvent(EGaEvents.CLICK_BACK, {
                                    event_option1: EGaCategories.AUTO_ADVANCE_PAYOUT_ENABLED,
                                    event_option2: !isDisableAllowed ? EGaTypes.DISABLE_BUTTON_INACTIVE : EGaTypes.DISABLE_BUTTON_ACTIVE,
                                    event_option4: activePlatform?.title
                                    });

                            }}
                            size={ButtonSizes.xl}>
                        {t('Back')}
                    </Button>
                </div>
            </div>
        </div>
    </Modal>
        <Modal isShow={isShowConfirmation} onClose={onCancelConfirmation}>
            <div className={'space-y-size-3'}>
                <div>
                    <h5 className={'text-header-05 font-bold font-heading mb-size-0.5'}>{t('Are you sure?')}</h5>
                    <p className="text-label-tertiary text-body-02">
                        <Trans i18nKey="autoPayoutDisableProcess">
                            The process of disabling Auto-payout will take <b className="font-bold">another 30 days.</b>
                            During this period you may reactivate Auto-payout.
                        </Trans>
                    </p>
                </div>
                <div className={'flex justify-between items-center'}>
                    <Button size={ButtonSizes.xl} onClick={() => {
                        sendGaEvent(EGaEvents.CLICK_STAY_WITH_AUTO, {
                            event_option1: EGaCategories.AUTO_ADVANCE_PAYOUT_ENABLED,
                            event_option2: !isDisableAllowed ? EGaTypes.DISABLE_BUTTON_INACTIVE : EGaTypes.DISABLE_BUTTON_ACTIVE,
                            event_option4: activePlatform?.title
                            });
                        onCancelConfirmation()
                    }}>
                    {t('Stay with Auto-payout')}
                    </Button>
                    <Button size={ButtonSizes.xl}
                            onClick={() => {
                                sendGaEvent(EGaEvents.CLICK_DISABLE_ANYWAY, {
                                    event_option1: EGaCategories.AUTO_ADVANCE_PAYOUT_ENABLED,
                                    event_option2: !isDisableAllowed ? EGaTypes.DISABLE_BUTTON_INACTIVE : EGaTypes.DISABLE_BUTTON_ACTIVE,
                                    event_option4: activePlatform?.title
                                    });
                                onDisable()
                            }}
                            theme={ButtonThemes.interfaceOnsurface}>
                        {t('Disable anyway')}
                    </Button>
                </div>
            </div>
        </Modal>
    </>
}
