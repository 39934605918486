import {CountriesSchemaType, VerificationTierStatusSchema} from "@capyfast/contracts";
import {getCommonApiClient} from "../client";
import {AxiosResponse} from "axios";

export function getVerificationTier1Countries() {
    return getCommonApiClient().get<
        CountriesSchemaType,
        AxiosResponse<CountriesSchemaType>
    >(`/api/internal/v1/geo/country/`)
}


export function setCountry(data: {
    'alpha_2_code': string,
    'region'?: string | null
}) {
    return getCommonApiClient()
        .post<VerificationTierStatusSchema, AxiosResponse<VerificationTierStatusSchema>>('/api/internal/v1/passport/user_verification/level_1/', data)
}
